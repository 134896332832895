import React, { useContext, useRef, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { AuthContext } from '../context/AuthContext';

function CatalogueAdd() {
    const { user } = useContext(AuthContext);

    const [processing, setProcessing]       = useState(false);
    const [addMsg, setAddMsg]               = useState("");
    const [msgClass, setMsgClass]           = useState("");

    const catalogueTitleRef                 = useRef();
    const catalogueTextAreaRef              = useRef();
    const cataTagRef                        = useRef();

    const [checkCat, setCheckCat]           = useState(false);

    async function handleFormCatalogue(e) {
        e.preventDefault();  
        
        if(catalogueTitleRef.current.value.length > 3  ){
            setAddMsg("");
            setProcessing(true);

            try{
                let salecode = "";
               
                if(checkCat === false){
                    salecode = "";
                }else{
                    salecode = cataTagRef.current.value;
                }

                const catalogItem = {
                    "title":catalogueTitleRef.current.value,
                    "information": catalogueTextAreaRef.current.value,
                    "salestag": checkCat,
                    "salescode": salecode,
                    "tokentrade": "",
                    "classification": [],
                    "tokentradeActive": false
                }

                const response = await axios.post(CONSTANTS.API_URL+"catalogues/", catalogItem, {
                    headers: {
                        token: "Bearer "+ user.accessToken
                    }
                });

                //console.log(response.data);

                catalogueTitleRef.current.value     = "";
                catalogueTextAreaRef.current.value  = "";
                setAddMsg("Catalogue has been created");
                setMsgClass("alert-success")
                setProcessing(false);
              }catch(err){
                setAddMsg("Something went wrong, please try again later.");
                setMsgClass("alert-danger")
                console.log(err.status);  
                setProcessing(false); 
                if(err.message === "Request failed with status code 403"){
                    localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
                    document.location.href="/login";
                   }             
              }

        }else {
            setAddMsg("Please add the title of the catalogue, no less than 3 characters");
            setMsgClass("alert-danger")
        }
    }

    const handleCatChange = () => {
        setCheckCat(!checkCat);
    };
  return (
    <div className="main-standard">
       <h2 className="lbl-title-page">Add Catalogue</h2>
       <div className="list-catalogue">
           <div className="card">
              <div className="card-body">
                 <form  onSubmit={handleFormCatalogue}>
                     <div className="form-group">
                        <div className="lbldesc">Catalogue Title*: </div>
                        <input type="text" ref={catalogueTitleRef} className="form-control wfull" required/>
                     </div>
                     <div className="form-group">
                        <div className="lbldesc">Infomation: </div>
                        <textarea ref={catalogueTextAreaRef} className="form-control wfull h100f"></textarea>                        
                     </div>
                     <input
                          type="checkbox"
                          checked={checkCat}
                          onChange={handleCatChange}
                        /> Saleslayer Tag<br /><br />

                    {
                        checkCat && (
                            <div className="form-group">
                                <div className="lbldesc">Ref Code*: </div>
                                <input type="text" ref={cataTagRef} className="form-control wfull" required/>
                            </div>
                        )
                    }
                     <div className="form-group">
                         <button type="submit" className="btn btn-picky" disabled={processing}>Create Catalogue</button>
                     </div>
                     {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                            </div>
                        )
                     }
                     {
                        addMsg && (
                            <div className={"alert-area " + msgClass}>
                               {addMsg}
                            </div>
                        )
                      }
                 </form>
              </div>
           </div>

       </div> 
    </div>
  
  )
}

export default CatalogueAdd