import React, { useEffect, useState } from 'react'
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";

function TBVcatSummary({person}) {

  const [processing, setProcessing]                                           = useState(false);
  const [scheduleSummary, setScheduleSummary]                                 = useState();
  const [noneSchedules, setNoneSchedules]                                     = useState();

  useEffect(() => {
      getSummary();
      getSummaryNoneSchedules();
  }, []);

  const getSummary = async () => {
    try {
           
        let config = {
          method: 'put',
          maxBodyLength: Infinity,
          url: CONSTANTS.API_URL +"pictures/collect/schedule/count",
          headers: { 
            'token': 'Bearer ' + person.accessToken
          }
        };

        const res = await axios.request(config);
      //console.log(res.data);
      setScheduleSummary(res.data);
      setProcessing(false);
    } catch (err) {
      setProcessing(false);      
      console.log(err);
      console.log("ERRRRRRR");
    }
  }

  const getSummaryNoneSchedules = async () => {
    try {
           
      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: CONSTANTS.API_URL +"pictures/collect/schedule/missing",
        headers: { 
              'token': 'Bearer ' + person.accessToken
            }
          };

          const res = await axios.request(config);
        
          //  console.log("BREAK DANCE");
          //  console.log(res.data);
          
        setNoneSchedules(res.data);
        setProcessing(false);
      } catch (err) {
        setProcessing(false);      
        console.log(err);
        console.log("ERRRRRRR");
      }
  }
  return (
    <div className="bx-catalo">
      <div className="font-medium text-3xl text-900 mb-3">Home Summary</div>
        <div className="copy-box">
            <h4>Schedules</h4>
            {
              scheduleSummary && (
                <div className="pd10">
                  <div className="flexme">
                      <div className="flfeed-block schedul-filt">
                        <div className="s-title">S0</div>
                        <strong>{scheduleSummary.S0}</strong>
                      </div>
                      <div className="flfeed-block schedul-filt"><div className="s-title">S1</div><strong>{scheduleSummary.S1}</strong></div>
                      <div className="flfeed-block schedul-filt"><div className="s-title">S2</div><strong>{scheduleSummary.S2}</strong></div>
                      <div className="flfeed-block schedul-filt"><div className="s-title">S3</div><strong>{scheduleSummary.S3}</strong></div>
                      <div className="flfeed-block schedul-filt"><div className="s-title">S4</div><strong>{scheduleSummary.S4}</strong></div>
                      <div className="flfeed-block schedul-filt"><div className="s-title">S5</div><strong>{scheduleSummary.S5}</strong></div>
                      <div className="flfeed-block schedul-filt"><div className="s-title">S6</div><strong>{scheduleSummary.S6}</strong></div>
                  </div>
                </div>
              )
            }
            
        </div>
        <div className="copy-box">
            <h4>None Schedules</h4>
            {
              noneSchedules && (
                <div className="pd10">
                    <div className="schedul-filt"><strong>{noneSchedules.count}</strong></div>
                </div>
              )
            }
            
        </div>
      

    </div>
  )
}

export default TBVcatSummary