import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../CONSTANTS";
import * as XLSX from 'xlsx';
import axios from "axios";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUpload, faFileExcel, faList } from '@fortawesome/free-solid-svg-icons';

function DlgImportTAssign(props) {

    const mainBarcodesRef                                       = useRef("");
    const mainCatalogueListRef                                  = useRef("");
    const [msgBarcodes, setMsgBarcodes]                         = useState("");
    const [msgBarClass, setMsgBarClass]                         = useState("");

    const [msgBarcodesAdvance, setMsgBarcodesAdvance]                         = useState("");
    const [msgBarAdvanceClass, setMsgBarAdvanceClass]                         = useState("");

    const [processing, setProcessing]                           = useState(false);

    async function handleExcelUploadAdvanced(e) {
        e.preventDefault();

        //  console.log("{{{{{{ Catalogue " + props.catid);
        //  console.log(props)
        //////////////////////
        const paradelete = {
            "paradelete":  props.catid
          }

          try {
            setProcessing(true);
            //Remove current listing
            const res = await axios.post(CONSTANTS.API_URL + "catalogues/delete/barcodes/picncat", paradelete, {
              headers: {
                token: "Bearer " + props.person.accessToken
              }
            });

           // console.log(res);
            if(res.status === 200){
                props.setAllPictures([]);
                    
                    const b = mainCatalogueListRef.current.files[0];
                    const data = await b.arrayBuffer();
                    ////////////////////////////
                    var workbook = XLSX.read(data);  
                    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                    const dataJson  = XLSX.utils.sheet_to_json(worksheet);
                    
                    const rNewPictures = {
                        "catalogueid" : props.catid,
                         "picturelist" : dataJson
                    }
                    //console.log(rNewPictures);
                    //post now to assign new listing
                    const resUpdates = await axios.post(CONSTANTS.API_URL + "catalogues/revised/barcodes/assignment/picncat", rNewPictures, {
                        headers: {
                          token: "Bearer " + props.person.accessToken
                        }
                      });
                     // console.log(resUpdates);
                      if(resUpdates.status === 200){
                        props.setUpdateMe(props.updateMe + 1);
                        setMsgBarcodesAdvance("Succesfully Updated");
                        setMsgBarAdvanceClass("alert-success");
                      }else {
                        setMsgBarcodesAdvance("We experienced a problem, please try again.");
                        setMsgBarAdvanceClass("alert-warning");
                      }
          
            }else {
                setMsgBarcodesAdvance("We experienced a problem, please try again.");
                setMsgBarAdvanceClass("alert-warning");
            }
            setProcessing(false);
          }catch(err){
            console.log(err);
            setProcessing(false);
            setMsgBarcodesAdvance("Something went wrong, please try again later.");
            setMsgBarAdvanceClass("alert-danger");
          }

    }

    async function handleExcelUpload(e) {
        e.preventDefault();
        console.log("Cata " + props.catid);
        const f = mainBarcodesRef.current.files[0];
        const data = await f.arrayBuffer();
        
        var workbook = XLSX.read(data);  
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const dataJson  = XLSX.utils.sheet_to_json(worksheet);
        const finalBarcodes  =  [];
        let   stringBarcodes = "";

        //console.log(dataJson);
        //console.log("<<<<<<<<<<<<<<<<<<<<<<<<<________>>>>>>>>>>>>>>>>>>>>")
        //console.log(dataJson[0]);
        if ('barcodes' in dataJson[0]){
            setMsgBarcodes("");
            setMsgBarClass("");
            setProcessing(true);
            const barcodesCat = {
                "catalogueId" :  props.catid,
                "data":dataJson
             }
             console.log(barcodesCat);
            ///////////////////////
            try{
                const res = await axios.put(CONSTANTS.API_URL +"catalogues/assignexcel/"+props.catid, {
                    dataJson
                }, {
                    headers: {
                        token: "Bearer "+ props.person.accessToken
                    }
                });
                console.log(res);
                console.log(res.data);
                //props.setDisplayTokenDialog(false);
                if(res.status === 201){
                    setMsgBarcodes("Successfully assigned the fields.");
                    msgBarClass("alert-success");
                }
                
            }
            catch(errors){
                console.log("Create TOKEN ERROR");
                console.log(errors)
            }
           ///////////////////////
           setProcessing(false);
        }else {
            console.log("We not good");
            setMsgBarcodes("The excel sheet should only carry one column with the title barcodes along with the list of barcodes below it. Check if there is no space after the column name as well.");
            setMsgBarClass("alert-warning");
        }
        
     
    }

  return (
        <Dialog header="Upload Excel Document" visible={props.dsplyImprtAsignDialog} style={{ width: '50vw' }} modal={true} onHide={() => props.setDsplyImprtAsignDialog(false)}
            footer={
                <div>
                    <Button className="btn btn-picky" label="Close" onClick={() => props.setDsplyImprtAsignDialog(false)} />
                </div>
            }>
      <div className="">
        <div className="tok-info">
            <div className="list-modal-items">
                <h4>Upload Excell</h4>
               <div className="flexme">
                  <div className="flx-70">
                        <form onSubmit={handleExcelUpload}>
                            <div className="input-block-group">                                         
                                <div className=" mgbot20">
                                    <span className="pd10 ico-tt-green"><FontAwesomeIcon icon={faFileExcel} /></span> <span>Add list of barcodes</span>
                                </div>
                                <input type="file" ref={mainBarcodesRef} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" className="form-control" required/>
                            </div>                                      

                            <div className="button-row">
                                <button className="btn btn-picky" >  <FontAwesomeIcon icon={faUpload} /> Import</button>  
                            </div>  
                        
                        </form>

                        {
                            msgBarcodes && (
                                <div className={"alert-area " + msgBarClass}>
                                    {msgBarcodes}
                                </div>
                            )
                        }
                        {
                            processing && (
                                <div className="img-source">
                                    <img src={CONSTANTS.SMALL_PROCESSING} className="proc profixed" />
                                </div>
                            )
                        }
                  </div>
                  <div className="flx-30">

                  </div>
               </div>
            </div>
        </div>

        <div className="tok-info">
            <div className="list-modal-items">
                <h4>Upload Excell Advance </h4>
                <div className="flexme">
                    <div className="flx-70">
                      <div className="pd10 bx-advanc">
                      <form onSubmit={handleExcelUploadAdvanced}>
                          <div className="input-block-group">
                            <div className=" mgtop10 mgbot20">
                               <div className="lbl">
                               <span className="pd10 ico-tt-green"><FontAwesomeIcon icon={faList} /> </span> <span>Specialized list <sub>Healthdeals</sub></span>
                               </div>
                                <input type="file" ref={mainCatalogueListRef} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" className="form-control" required/>
                            </div>
                            <div className="button-row">
                                <button className="btn btn-picky" >  
                                  <FontAwesomeIcon icon={faUpload} /> Make Submission
                                </button>  
                            </div>  

                          </div>
                      </form>
                      </div>

                      {
                            msgBarcodesAdvance && (
                                <div className={"alert-area " + msgBarAdvanceClass}>
                                    {msgBarcodesAdvance}
                                </div>
                            )
                        }
                    </div>
                    <div className="flx-30"></div>
                </div>
            </div>
        </div>
      </div>
    </Dialog>
  )
}

export default DlgImportTAssign