import React, { useEffect, useState } from 'react'
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import './home.css';

function TotalPictures() {
    const [total, setTotal]                             = useState(0);

    const [leftPerc, setLeftPerc]                       = useState(0);
    const [rightPerc, setRightPerc]                     = useState(0);
    const [unpackPerc, setUnpackPerc]                   = useState(0);

    useEffect(() => {
        getTheTotalPictures();
    },[]);

    const getTheTotalPictures = async () => {
        try{
            const response = await axios.get(CONSTANTS.API_URL + "pictures/imgratecounts"); 
            console.log(response.data);
            setTotal(response.data.total);

            //calculate the left left over percentage of how many images have left views
            const lcount             = response.data.total - response.data.left;
            const leftOverPercentage = (lcount / response.data.total ) * 100;
            setLeftPerc(Math.round(leftOverPercentage));

            //calculate the right left over percentage of how many images have right views
            const rcount             = response.data.total - response.data.right;
            const rightOverPercentage = (rcount / response.data.total ) * 100;
            setRightPerc(Math.round(rightOverPercentage));

            //calculate the right left over percentage of how many images have right views
            const ucount             = response.data.total - response.data.unpack;
            const unPackOverPercentage = (ucount / response.data.total ) * 100;
            setUnpackPerc(Math.round(unPackOverPercentage));
        }catch(err){
            console.log("Err Totals");
            console.log(err)
        }
    }

  return (
    <div className="col-md-9">
            <div className="card flex-mg-space ">
                <div className="card-body widget-body">
                    <div className="flexme">
                        <div className="home-dash-item flx-50">
                            <div className="ico-title ico-tt-green">
                            <FontAwesomeIcon icon={faListAlt} /> 
                            </div>
                            <h3 className="hdi-title">Total Images</h3>
                            <div className="numFeed">
                                {total}
                            </div>
                        </div>
                        <div className="flx-50">
                            <div className="ge-percecount">
                            <div className="img-tit imgLFT">Left IMAGE</div>
                            <div className="sect-perc flexme">
                                <div className="ct-perc" >{leftPerc} %</div>
                                <div className="barcont">
                                    <div className="main-progress-bar" style={{width: `${leftPerc}%`}} >
                                    </div>
                                </div>
                            </div>
                            <div className="img-tit imgR">Right IMAGE</div>
                            <div className="sect-perc flexme">
                                <div className="ct-perc" >{rightPerc} %</div>
                                <div className="barcont">
                                    <div className="main-progress-bar" style={{width: `${rightPerc}%`}} >
                                    </div>
                                </div>
                            </div>

                            <div className="img-tit imgR">unpack IMAGE</div>
                            <div className="sect-perc flexme">
                                <div className="ct-perc" >{unpackPerc} %</div>
                                <div className="barcont">
                                    <div className="main-progress-bar" style={{width: `${unpackPerc}%`}} >
                                    </div>
                                </div>
                            </div>
                            
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
  )
}

export default TotalPictures