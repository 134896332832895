import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext';
import * as CONSTANTS from "../CONSTANTS";
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


function PhotoList() {
    const [pictureList, setPictureList]                       = useState([]);
    const [pictureListArchive, setPictureListArchive]         = useState([]);
    const [picCount, setPicCount]                             = useState(0);
    const [globalFilter, setGlobalFilter]                     = useState('');
    const [stringSearch, setStringSearch]                       = useState('');
    const [searchPictures, setSearchPictures]                   = useState([]);
    const [searchProc, setSearchProc]                           = useState(false);

    const [processing, setProcessing]              = useState(false);

    const navigate = useNavigate()

    const { user } = useContext(AuthContext);

    useEffect(() => {

      if(!user.isAdmin){
        navigate("/access-denied");
      }        
     // getPhotos();
    }, [])

    function handleTableFilter(event) {
   
      const newData = pictureListArchive.filter(row => {
          const searchText = event.target.value.toLowerCase();
         // return row.title.toLowerCase().includes(event.target.value.toLowerCase())
         return (
          row.title.toLowerCase().includes(searchText) ||
          row.barcode.toLowerCase().includes(searchText) ||
          row.onlineDesc.toLowerCase().includes(searchText) ||
          row.description.toLowerCase().includes(searchText) 
         )
      });
      setPictureList(newData);
  }
  /////////////////////////////////////////////////////////////////////////////////////
   const headerProductTable = (
      <div className="table-header flex flexymobile justify-content-between">
        <h4 className="mx-0 my-1">Pictures  ({picCount})</h4>
        {
          (pictureList.length > 0) && (
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={handleTableFilter} placeholder="Search Pictures..." />
            </span>
          )
        }
        
      </div>
    );
  /////////////////////////////////////////////////////////////////////////////////////
      const imageProductTemplate = (rowData) => {     
      return <div className="img--box"><Link to={"/photo/" + rowData._id} className="link-no-styling"><img src={rowData.imgurl} alt="image" className="box-image"  /></Link></div>;
    }
  /////////////////////////////////////////////////////////////////////////////////////
    const viewPhotoTemplate = (rowData) => {
      return <Link to={"/photo/" + rowData._id} className="btn btn-picky btsm1">View</Link> 
    }
  /////////////////////////////////////////////////////////////////////////////////////
  const viewOnlineShortDesccriptionTemplate = (rowData) => {
    return (rowData.onlineDesc.length > 160 ? <>{rowData.onlineDesc.substring(0, 160)} ...</> : <>{rowData.onlineDesc}</>)
  }
  /////////////////////////////////////////////////////////////////////////////////////
  const viewCategoryTemplate = (rowData) => {
    var catName = "";
    try{
      if(rowData.category[0].id !== undefined){
        catName = rowData.category[0].name;
      }
    }catch(err){
      console.log("+|||||||||+");
      console.log(rowData);
      console.log(err);
    }
    return catName
  }
  /////////////////////////////////////////////////////////////////////////////////////
    const getPhotos = async () => {
       // console.log("LIbrary")
       // console.log(user);
       setProcessing(true);
        try {
            //console.log("Coaching...")
            //console.log(CONSTANTS.API_URL+"pictures/free?page="+page+"&limit="+plimit)
            const res = await axios.get(CONSTANTS.API_URL +"pictures/assembly", {
                  headers: {
                      token: "Bearer "+ user.accessToken
                  }
              });
 
            //console.log(res.data);
            setPictureList(res.data);
            setPictureListArchive(res.data);
            setPicCount(res.data.length);
            //console.log("+++++++++++++++++++++++++++");
            setProcessing(false);
          } catch (err) {
            console.log(err);
            console.log("ERRRRRRR");
            setProcessing(false);
            if(err.message === "Request failed with status code 403"){
              localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
              document.location.href="/login";
             }
          }
    }

    const handleChangeSearch = (event) => {
      // 👇 Get input value from "event"
      setStringSearch(event.target.value);
      console.log(event.target.value);
  
      if(event.target.value.length > 2){       
        executSearchNow(event.target.value);
      }else {
        setSearchProc(false); 
        setSearchPictures([]);    
      }
    };

    const executSearchNow = async (content) => {
      console.log("%%%%%%%%%");
      console.log(content);
     try{
          setSearchProc(true);
  
          setSearchPictures([]);
          const resAutoPic = await axios.get(CONSTANTS.API_URL +"pictures/autocomplete/product?searchString=" + content);
          console.log(resAutoPic.data);
          setSearchPictures(resAutoPic.data);
          setSearchProc(false);
          
     }catch(error){
      setSearchProc(false);
        console.log(error);
     }
    }
  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">Library</h2>
        <div className="card">

        <div className="card-body mobOff">
          <div className="content-box">
             <div className="flexme">
                <div className="wide50">
                    <div className="special-search pd10">
                      <h3>Picture Auto Search</h3>
                      <div className="inp-text">
                            <input
                                type="text"
                                className="searchmain"
                                id="message"
                                name="message"
                                onChange={handleChangeSearch}
                                placeholder="3+ characters..."
                              />
                          </div>
                    </div>
                </div>
             </div>
          </div>
              <div className="row ">
                 <div className="content-box">
                          {
                            searchProc &&
                              (<div className="process-found">loading...</div>)
                              
                          } 
                          <div className="src-diplay">
                              {
                                searchPictures && (
                                  <div className="row">
                                  {
                                    searchPictures.map((pict, index) => {
                                      return (
                                        <div className="col-md-4" key={index}>
                                          <div className="data-product">
                                              <div className="thin">{pict.barcode}</div>
                                              <Link to={"/photo/" + pict._id} className="lk-dash">
                                                <h4>{pict.title}</h4>
                                              </Link>
                                              <div className="descript-1">
                                                {
                                                
                                                  (pict.onlineDesc.length > 100) 
                                                   
                                                  ? 
                                                    <div className="in-ct">
                                                      {pict.onlineDesc.substr(0, 100)}...
                                                    </div>
                                                  :
                                                  <div className="in-ct">
                                                    {pict.onlineDesc}
                                                   </div>
                                                }
                                              </div>
                                              <div className="image-1">
                                                <Link to={"/photo/" + pict._id} className="lk-dash">
                                                   <img src={pict.imgurl} className="image-full" />
                                                 </Link>
                                              </div>
                                          </div>
                                      </div>
                                      )
                                    })
                                  }

                                  </div>
                                )
                              }
                          </div>
                     </div>
                 
              </div>
        </div>
              {
                  processing && (
                      <div className="img-source">
                      <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc profixed" />
                      </div>
                  )
                }

      </div>
    </div>  
  )
}

export default PhotoList