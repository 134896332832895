import React, { useContext, useState } from 'react';
import './settings.css';
import TBVPictures from '../components/TBVPictures';
import TBVCatalogue from '../components/TBVCatalogue';
import TBVFormGal from '../components/TBVFormGal';
import { AuthContext } from '../context/AuthContext';

function KnowledgeBase() {
    const [activeIndex, setActiveIndex] = useState(1);
    
    const { user } = useContext(AuthContext);
     //functions
     const toggleSelectedTab = (index) => {
        setActiveIndex(index);
    }
  return (
    <div className="main-standard">
       <h2 className="lbl-title-page">Knowledge Base</h2>

       <div className="tab-dashboard-item">
                <div className="side-dash-settings-nav">
                    <div className={activeIndex === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleSelectedTab(1)}>
                       Formulate Gallery
                    </div>
                    <div  className={activeIndex === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleSelectedTab(2)}>
                       Catalogue API
                    </div>
                    <div  className={activeIndex === 3 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleSelectedTab(3)}>
                         Picutre API
                    </div>
                    
                </div>

                <div className="tab-content-box">
                        <div
                            className={activeIndex === 1 ? "content-tab content-active" : "content-tab"}
                            >
                              <TBVFormGal />
                        </div>
                        <div
                            className={activeIndex === 2 ? "content-tab content-active" : "content-tab"}
                            >
                            <TBVCatalogue />
                        </div>
                        <div
                            className={activeIndex === 3 ? "content-tab content-active" : "content-tab"}
                            >
                            <TBVPictures />
                        </div>
                        
                </div>
           </div>   
    </div>
  )
}

export default KnowledgeBase