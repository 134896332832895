import React, { useEffect, useRef, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import * as XLSX from 'xlsx';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUpload, faAdjust } from '@fortawesome/free-solid-svg-icons';

function TBVSetScheduleToPics({person, active}) {

    const [msgFeed, setMessageText]                                     = useState("");
    const [msgClass, setMsgClass]                                       = useState("");
    const [processing, setProcessing]                                   = useState(false);

    const [messageStatus, setMessageStatus]                             = useState([]);
    const [messageCurrent, setMessageCurrent]                           = useState("");
    const [notFoundCount, setNotFoundCount]                             = useState(0); // State for "Not found" count
    const [foundCount, setFoundCount]                                   = useState(0); // State for "Found" count

    const [listFeedBack, setListFeedBack]                               = useState([]);
    const mainScheduleListRef                                           = useRef();

    const mainScheduleListDivergentRef                                  = useRef();

    const handleUploadDivergentScheduleList = async (e) => {
        e.preventDefault();
        
        try{

            const excelDocument             = mainScheduleListDivergentRef.current.files[0];
            const data                      = await excelDocument.arrayBuffer();
            
            var workbook = XLSX.read(data);  
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const dataJson  = XLSX.utils.sheet_to_json(worksheet);
            //console.log(dataJson);
            const pictureCount = dataJson.length;

            if (pictureCount > 0){
                for (let i = 0; i < pictureCount; i++) {
                      if( dataJson[i].BARCODE ){                    
                        ////////////////
                        try{

                            let scheduleText = dataJson[i].SCHEDULE;

                            if(scheduleText === "N" || scheduleText === ""){
                                scheduleText = "0";
                            }

                            const item = {
                                "barcode": dataJson[i].BARCODE,
                                "code" :  dataJson[i].CODE,
                                "title" :  dataJson[i].DESCR,
                                "schedule" :  scheduleText
                            }

                            setMessageCurrent("Current Product " + dataJson[i].BARCODE);
                            const responseUpdate = await axios.put(CONSTANTS.API_URL + "pictures/v1/diverge/col/schedule/" , item, {
                                headers: {
                                  token: "Bearer " + person.accessToken
                                }
                              });

                                //console.log(responseUpdate);
                                let respondString = "";
                                if(responseUpdate.data !== null){
                                    console.log("Found Feedback");
                                    //console.log(responseUpdate.data)
                                    respondString = dataJson[i].BARCODE + " - Schedule Diverge.";
                                    setFoundCount(prevCount => prevCount + 1);
                                }else {
                                    //console.log("Nulll");
                                    respondString = dataJson[i].BARCODE + " - Not found";
                                    setNotFoundCount(prevCount => prevCount + 1);

                                }

                                console.log("{{{{{{{{{{{{{{{{ - }}}}}}}}}}}}"); 
                                // Push the response string to the front of messageStatus
                                setMessageStatus(prevState => [respondString, ...prevState]);

                        }catch(err){
                            console.log(err);
                        }
                        ///////////////
                    }
                }
            }

        }catch(err){
            console.log(err);
        }
    }

    const handleUploadScheduleList = async (e) => {
        e.preventDefault();

        try{

            const excelDocument = mainScheduleListRef.current.files[0];
            const data          = await excelDocument.arrayBuffer();
            
            var workbook = XLSX.read(data);  
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const dataJson  = XLSX.utils.sheet_to_json(worksheet);
            //console.log(dataJson);
            const pictureCount = dataJson.length;
            //console.log(pictureCount);
            if (pictureCount > 0){
                ///////////////////........>>>>>>>>
                setProcessing(true);
                for (let i = 0; i < pictureCount; i++) {
                //for (let i = 0; i < 100; i++) {
                    if( dataJson[i].BARCODE ){
                        try{
                            var discontinue;
                            if (dataJson[i].DESCR.startsWith("DELETE ")) {
                                console.log("The string starts with DELETE.");
                                discontinue  = true;
                            } else {
                                console.log("The string does not start with DELETE.");
                                discontinue = false;
                            }


                            let scheduleText = dataJson[i].SCHEDULE;

                            if(scheduleText === "N" || scheduleText === ""){
                                scheduleText = "0";
                            }

                            const item = {
                                "barcode": dataJson[i].BARCODE,
                                "code" :  dataJson[i].CODE,
                                "title" :  dataJson[i].DESCR,
                                "schedule" :  scheduleText,
                                "discontinue" : discontinue
                            }

                            setMessageCurrent("Current Product " + dataJson[i].BARCODE);
                            const responseUpdate = await axios.put(CONSTANTS.API_URL + "pictures/add/col/schedule/" , item, {
                                headers: {
                                  token: "Bearer " + person.accessToken
                                }
                              });


                                //console.log(responseUpdate);
                                let respondString = "";
                                if(responseUpdate.data !== null){
                                    console.log("Found Feedback");
                                    //console.log(responseUpdate.data)
                                    respondString = dataJson[i].BARCODE + " - Schedule Updated.";
                                    setFoundCount(prevCount => prevCount + 1);
                                }else {
                                    //console.log("Nulll");
                                    respondString = dataJson[i].BARCODE + " - Not found";
                                    setNotFoundCount(prevCount => prevCount + 1);

                                }

                                console.log("{{{{{{{{{{{{{{{{ - }}}}}}}}}}}}"); 
                                 // Push the response string to the front of messageStatus
                                 setMessageStatus(prevState => [respondString, ...prevState]);
                            } catch(err){
                                console.log(err)
                            }
                    }else {
                        console.log("NO barcode");
                    }                   
                     
                }
                setProcessing(false);
                ///////////////////........>>>>>>>>
            }
            /*
            if (pictureCount > 0){
                setProcessing(true);
                for (let i = 0; i < pictureCount; i++) {
                    console.log("{{{{{{{{{{{{{{{{}}}}}}}}}}}}");   
                    console.log("{{{{{{{{{{{{{{{{}}}}}}}}}}}}");   
                    console.log("{{{{{{{{{{{{{{{{}}}}}}}}}}}}");   
                    console.log("{{{{{{{{{{{{{{{{}}}}}}}}}}}}");   

                    const barcodesUpdate = {
                        "barcode" :  dataJson[i].barcode,
                        "stringUrl":textImageStandRef.current.value
                     }

                     console.log(barcodesUpdate);       
                    
                    try{
                        const responseUpdate = await axios.put(CONSTANTS.API_URL + "pictures/set/schedule/product" , barcodesUpdate, {
                            headers: {
                              token: "Bearer " + person.accessToken
                            }
                          });
                          console.log(responseUpdate);   
                          var messageBack   = "";

                          if(responseUpdate.status === 200){
                            messageBack = "Successfully added - " + responseUpdate.data.title + " (" + responseUpdate.data.barcode + ")";
                          }else {
                            messageBack = dataJson[i].barcode + " - Update unsuccesfull.";
                          }
                         

                          setListFeedBack([...listFeedBack, messageBack]);
                          console.log("*****");
                          console.log(listFeedBack);

                    }catch(erItem){
                        console.log(erItem)
                    }
                    
                }

                setProcessing(false);
                setMsgClass("alert-success");
                setMessageText("Processing is complete.");
              
            }else {
                setMsgClass("alert-danger");
                setMessageText("Excell sheet is empty");
            }
            */

        }catch(err){
            console.log(err);
            setProcessing(false);
        }
        
    }

  return (
    <div className="tv-box">
        <h3>Add Schedules</h3>
        <div className="row rs-item">
            <div className="col-md-7">
                    <div className="schedules-block mgbot20">
                        <form onSubmit={handleUploadScheduleList}>
                            <div className="input-block-group">                                       
                                <input type="file" ref={mainScheduleListRef} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" className="form-control" required/>
                            </div>                                                             
                            <div className="button-row">
                                <button className="btn btn-picky" >  
                                    <FontAwesomeIcon icon={faUpload} /> Import
                                </button>  
                            </div>                      
                        </form>
                    </div>
                    <div className="snip-feedback">
                        <div className="current-item-back">
                            {messageCurrent}
                        </div>
                        <div className="current-item-back">
                            <div className="flexme">
                                <div className="flfeed-block">
                                    <h4 className="txtsmall">FOUND</h4>
                                    {foundCount}
                                </div>
                                <div className="flfeed-block">
                                    <h4 className="txtsmall">NOT FOUND</h4>
                                    {notFoundCount}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="snip-feedback">
                        Divergent 
                        <form onSubmit={handleUploadDivergentScheduleList}>
                            <div className="input-block-group">                                       
                                <input type="file" ref={mainScheduleListDivergentRef} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" className="form-control" required/>
                            </div> 
                            <div className="button-row">
                                <button className="btn btn-picky" >  
                                    <FontAwesomeIcon icon={faAdjust} /> Adjust
                                </button>  
                            </div>      
                        </form>
                           
                    </div>
            </div>
            <div className="col-md-5">
                <div className="message-scroll">
                    {
                        messageStatus.length > 0 && (
                            <div className="list-blow">
                               <ul className="no-bullet">
                                  {
                                    messageStatus.map( (message, index) => {
                                        return (
                                            <li className="mong-item item" key={index}>
                                                {message}                                                
                                            </li>
                                        )
                                    })
                                  }
                               </ul>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
        {
            processing && (
                <div className="img-source">
                    <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                </div>
            )
        }
    </div>
  )
}

export default TBVSetScheduleToPics