import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";

function DlgAddTokenCat(props) {
    const [processing, setProcessing]                      = useState(false);

    const [proc1, setProcOne]                              = useState(false);
    const [proc2, setProcTwo]                              = useState(false);
    const [proc3, setProcThree]                            = useState(false);

    const [tokenString, setTokenString]                    = useState("");
    const [tokenObject, setTokenObject]                    = useState("");

    const [activeAdva, setActivAdva]                       = useState(false);
    const [tokenTradeString, setTokenTradeString]          = useState("");
    
    const [barcodesExtList, setBarcodeExList]              = useState([]);
    const [barcFilteredRight, setBarcFilteredRight]        = useState([]);
    const [stepBarCount, setStepBarCount]                  = useState(1);
    const [prodCount, setProdCount]                        = useState(0);
    const [prodCountGo, setProdCountGo]                    = useState(0);
   

    const [currentCatalogue, setCurrentCatalogue]          = useState({});
    const [messaClass, setMassaClass]                      = useState("");
    const [message, setMessage]                            = useState("");

    const tokenStringInput                                 = useRef();
    const urlExternalRef                                   = useRef();

    useEffect(()=> {     
        fetchCurrentToken();
        tokenSetUpCheck();
         
    },[])

    const fetchCurrentToken = async () => {
        try{
            /*
                var catData = JSON.stringify({
                    "catalogueId": props.catid
                });
              */
             var catString = props.catid
             // console.log(catString);
           
            var config = {
                method: 'get',
                url: CONSTANTS.API_URL + 'tokens/catalogue/'+ catString,
                headers: { 
                  'token': 'Bearer '+ props.person.accessToken, 
                  'Content-Type': 'application/json'
                }
              };

            const resToken = await axios(config);
            /*
            console.log("token Message")
            console.log(resToken.data);
             */
             if(resToken.data[0]){
                setTokenObject(resToken.data[0]);
                setTokenString(resToken.data[0].token)
             }
            
        }catch(erToken){
            console.log(erToken);
            console.log("Error Fetching Token ");
        }
    }

    //Get current catalogue again
    const tokenSetUpCheck = async () => {
        var catString = props.catid
       // console.log(catString);

        try { 

           const res = await axios.get(CONSTANTS.API_URL + "catalogues/details/" + catString, {
             headers: {
               token: "Bearer " + props.person.accessToken
             }
           });
     
           //console.log(res.data[0]);
           //console.log("[][][][][][][][][][][][][][][][]");
           setCurrentCatalogue(res.data[0]);
           setActivAdva(res.data[0].tokentradeActive);
           setTokenTradeString(res.data[0].tokentrade);
         } catch (err) {
           console.log(err);
         }
    }

    //basic auth token, update the token
    const handleCreateTokenUpdate = async () => {
       
        try{
           
            const res = await axios.put(CONSTANTS.API_URL +"tokens/tokenupdate", {
                tokenId: tokenObject._id
            }, {
                headers: {
                    'token': 'Bearer '+ props.person.accessToken, 
                }
            });

            //console.log(res);
            //console.log(res.data);
            //props.setDisplayTokenDialog(false);
            setTokenObject(res.data.updatedToken);
            setTokenString(res.data.updatedToken.token)
        }
        catch(errors){
            console.log("Create TOKEN ERROR");
            console.log(errors)
        }
      
    }
    //basic auth token, add the token
    const handleCreateToken = async () => {       
        try{

            const res = await axios.post(CONSTANTS.API_URL +"tokens", {
                catalogueId: props.catid
            }, {
                headers: {
                    'token': 'Bearer '+ props.person.accessToken, 
                }
            });
          
            setTokenObject(res.data);
            setTokenString(res.data.token);

        }
        catch(errors){
            console.log("Create TOKEN ERROR");
            console.log(errors)
        }
      
    }

    //Advance auth token, view it for use
    const handleActivateToken = async () => {
        setActivAdva(true);
    }

     //Advance auth token, view it for use
     const handleActivationOfTokenAdvance = async () => {

        const tokenInput = tokenStringInput.current.value;
        if(tokenInput.length > 8){
            setProcessing(true);
            setMassaClass("");
            setMessage("");
             try{

                const catalogItem = {
                    "catalogue_id": props.catid, 
                    "tokentrade": tokenInput,
                    "tokentradeActive": true
                }
    
                console.log(catalogItem);
                const response = await axios.post(CONSTANTS.API_URL+"catalogues/updatedata", catalogItem, {
                    headers: {
                        token: "Bearer "+ props.person.accessToken
                    }
                });
    
                console.log(response.data);
       
                if(response.status === 200){
                    setMassaClass("alert-success");
                    setMessage("Token was now successful.");
                }else {
                    setMassaClass("alert-danger");
                    setMessage("Token could not be updated. Please try again later.");
                }

                setTokenTradeString(tokenInput);
                setProcessing(false);
             }catch(err){
                console.log(err);
                setProcessing(false);
                setMassaClass("alert-danger");
                setMessage("Something went wrong, please try again later.");
             }
        }else {
            setMassaClass("alert-danger");
            setMessage("Token cannot be less than 8 characters");
        }
    }

    const collectProducts  = async () => {
        const urlInput = urlExternalRef.current.value;
        if(urlInput.length > 8){

            setMassaClass("");
            setMessage("");
            setProcOne(true);

            //console.log(tokenTradeString);
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: urlInput,
                headers: { 
                  'Authorization': 'Bearer '+ tokenTradeString
                }
              };
              
              axios.request(config).then((response) => {
                //console.log(JSON.stringify(response.data));
               
                  if(response.data.success){
                    const barcodesTradeNew = response.data.content.product_list.map(product => product.barcode);
                    
                    /*
                    console.log("De Barcode Ntate");
                    console.log(barcodesTradeNew);
                    */
                    setBarcodeExList(barcodesTradeNew);
                    setProdCount(barcodesTradeNew.length)
                    
                    setProcOne(false);                    
                  }else {
                    setMassaClass("alert-danger");
                    setMessage("Request status was not a success.");
                    setProcOne(false);
                  }

              }).catch((error) => {
                console.log("Axios");
                console.log(error);
                setMassaClass("alert-danger");
                setMessage("Something went wrong externally, Please try again later.");
                setProcOne(false);
              });
              
        }else {
            setMassaClass("alert-danger");
            setMessage("Please enter a full valid url");
        }
    }

    const addBarcodeToCatalogue = async () => {
        setProcTwo(true);
        try{

            let textBarcodes = barcodesExtList.toString();
          
            const bars = {
                "barcodelist": textBarcodes
            }

            console.log(bars);

            const response = await axios.post(CONSTANTS.API_URL+"catalogues/externalbarlist", bars, {
                headers: {
                    token: "Bearer "+ props.person.accessToken
                }
            });

            console.log(response.data);
            setProdCountGo(response.data.new_total);
    
            setBarcFilteredRight(response.data.new_barcodes);
            setStepBarCount(stepBarCount + 1);
            setProcTwo(false);

        }catch(err){
            setMassaClass("alert-danger");
            setMessage("Something went wrong, please try again later.");
            setProcTwo(false);
        }
    }
    const addBarcodeToCatalogueExecute  = async () => {
        setProcThree(true);
        try{
            
            //barcFilteredRight
            let textBarcodes = barcFilteredRight.toString();
          
            const bars = {
                "catalogue_id": props.catid, 
                "barcodelist": textBarcodes
            }

            const response = await axios.post(CONSTANTS.API_URL+"catalogues/addtosetcarts", bars, {
                headers: {
                    token: "Bearer "+ props.person.accessToken
                }
            });

            if(response.status === 200){
                setMassaClass("alert-success");
               setMessage("Successfully added.");
            }else {
                setMassaClass("alert-danger");
                setMessage("Something went wrong, please try again later.");
            }
            setProcThree(false);
        }catch(err){
            setMassaClass("alert-danger");
            setMessage("Something went wrong, please try again later.");
            setProcThree(false);
        }
    }
  return (
        <Dialog header="Catalogue Token" visible={props.dspCreateCatTokenDialog} style={{ width: '80vw' }} modal={true} onHide={() => props.setDspCreateCatTokenDialog(false)}
        footer={
            <div> 
                <Button className="btn btn-picky" label="Close" onClick={() => props.setDspCreateCatTokenDialog(false)} />
            </div>
        }>
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-12">
             
               <div className="tok-info">
                    <h3>Basic Auth</h3>
                    To make requests to your catalogue data please use the following Token.

                    {
                        tokenString && (
                            <p className="token-display">{tokenString}</p>
                        )
                    }
                    <div className="tk-but">
                       <div className="buttons-foot">
                        {
                            tokenString ?
                                <Button className="btn btn-picky" label="Create New Token" onClick={handleCreateTokenUpdate} />
                                :
                                <Button className="btn btn-picky" label="Create Token" onClick={handleCreateToken} />
                        }
                       </div>
                    </div>
                </div>
               <div className="tok-info">
                    <h3>Advanced Auth</h3>
                    This would be the external token.

                    {
                            activeAdva && (
                                <div className="form-token">
                                    <div className="">Add external Token</div>
                                    <input type="text" className="form-control txtsmall1" placeholder="Add Token" ref={tokenStringInput} defaultValue={tokenTradeString}/>
                                    <Button 
                                        className="btn btn-picky" 
                                        label="Execute Token"
                                        onClick={handleActivationOfTokenAdvance}
                                        />

                                        <div className="inner-box-bot">
                                               <div className="flexme ritems">
                                                 <div className={`dex-box ${(stepBarCount > 0) ? "acto" : ""}`}>
                                                            <div className="box-head">   
                                                               <h2> Collect Data URL</h2>
                                                                <input type="text" className="form-control txtsmall0" ref={urlExternalRef} />
                                                            </div>
                                                            <div className="but-box"> 
                                                                <button className="btn btn-picky" onClick={collectProducts}>Start</button>
                                                                <div className="">
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className="content-info">  
                                                               {
                                                                  proc1 && (
                                                                    <div className="min-process">
                                                                        <img src={CONSTANTS.SMALL_PROCESSING} className="pr-small-bo" />
                                                                    </div>
                                                                  )
                                                               }    
                                                               {
                                                                (prodCount > 0)  && (
                                                                    <div className="">
                                                                        <strong>{prodCount} </strong> Total products
                                                                    </div>
                                                                )
                                                               }                                                            
                                                            </div>
                                                    </div>  
                                                    <div className={`line-index ${(stepBarCount > 1) ? "actoline" : ""}`}>
                                                    </div> 
                                                    <div className={`dex-box ${(stepBarCount > 1) ? "acto" : ""}`}>
                                                            <div className="box-head">   
                                                               <h2> Shortage Review</h2>
                                                               <p className="ft12">Connect found images to this catalogue.</p>
                                                            </div>
                                                            {
                                                                (prodCount > 0) && (
                                                                    <div className="but-box"> 
                                                                        <button className="btn btn-picky" onClick={() => addBarcodeToCatalogue()}>Link Image</button>
                                                                    </div>
                                                                )
                                                              }
                                                            <div className="content-info">  
                                                                {
                                                                  proc2 && (
                                                                    <div className="min-process">
                                                                        <img src={CONSTANTS.SMALL_PROCESSING} className="pr-small-bo" />
                                                                    </div>
                                                                  )
                                                               }
                                                               {
                                                                (prodCountGo > 0)  && (
                                                                    <div className="">
                                                                        <strong>{prodCountGo} </strong> Total products
                                                                    </div>
                                                                )
                                                               }  
                                                            </div>
                                                     </div>  

                                                     <div className={`line-index ${(stepBarCount > 2) ? "actoline" : ""}`}>
                                                    </div> 
                                                    <div className={`dex-box ${(stepBarCount > 2) ? "acto" : ""}`}>
                                                            <div className="box-head">   
                                                               <h2> Save List</h2>
                                                               <p className="ft12">Save list now.</p>
                                                            </div>
                                                            {
                                                                (prodCountGo > 0) && (
                                                                    <div className="but-box"> 
                                                                        <button className="btn btn-picky" onClick={() => addBarcodeToCatalogueExecute()}>Save List</button>
                                                                    </div>
                                                                )
                                                              }
                                                            <div className="content-info">  
                                                                {
                                                                  proc3 && (
                                                                    <div className="min-process">
                                                                        <img src={CONSTANTS.SMALL_PROCESSING} className="pr-small-bo" />
                                                                    </div>
                                                                  )
                                                               }
                                                               
                                                            </div>
                                                     </div>  


                                                </div>      
                                        </div>
                                </div>
                            )
                    }

                    <div className="tk-but">
                        <div className="buttons-foot">
                      
                            {
                                 (currentCatalogue.tokentradeActive === false) ? 
                                    <Button 
                                        className="btn btn-picky" 
                                        label="Activate Token"
                                        onClick={handleActivateToken}
                                      />
                                    :
                                    ""
                            }
                        </div>
                    </div>
               </div>

               {
                    messaClass && (
                        <div className={"alert " + messaClass}>
                            {message}
                        </div>
                    )
               }
               {
                    processing && (
                        <div className="img-source">
                        <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                        </div>
                    )
                }
            </div>
        </div>


    </Dialog>
  )
}

export default DlgAddTokenCat