import React, { useEffect, useState } from 'react'
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import BroadSheetMain from './broad/BroadSheetMain';
import BSheetCensusReport from './broad/BSheetCensusReport';
import BSheetAssigning from './broad/BSheetAssigning';

function TBVCatBroadSheet({person, activeIndex}) {
    const [processing, setProcessing]                                                     = useState(false);

    const [emptyList, setEmptyList]                                                       = useState("");
    const [broadSheet, setBroadSheet]                                                     = useState([]);
    const [broadIndex, setBroadIndex]                                                     = useState(1);
   
    useEffect(() => {
        if(activeIndex === 4){
            getBroadSheetFullList();
        }        
    },[activeIndex]);

    const getBroadSheetFullList = async () => {
        setProcessing(true);
          try { 
    
            const res = await axios.get(CONSTANTS.API_URL + "broadsheets/list/v2/", {
              headers: {
                token: "Bearer " + person.accessToken
              }
            });

            console.log(res);            
            setBroadSheet(res.data);
            setProcessing(false);
          } catch (err) {
            console.log(err);
            setProcessing(false);
            if(err.message === "Request failed with status code 403"){
              localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
              document.location.href="/login";
            } 
          }    
      }

    //functions
    const toggleSelectedTab = (index) => {
        setBroadIndex(index);
    }

  return (
    <div  className="bx-catalo">
        <div className="list-catalogue">
            <div className="card">
            <div class="font-medium text-3xl text-900 mb-3">Broad Sheet</div>              
              <div className="sect-cont-dial">
                {
                    broadSheet &&
                    broadSheet.length > 0 && (
                        <div className="broad-list-base">
                
                            <div className="top-dash-nav">
                                <div className={broadIndex === 1 ? "tabs active-tabs" : "tabs"}
                                    onClick={() => toggleSelectedTab(1)}>
                                    Home
                                </div>
                                <div className={broadIndex === 2 ? "tabs active-tabs" : "tabs"}
                                    onClick={() => toggleSelectedTab(2)}>
                                    Census Report
                                </div> 
                                <div className={broadIndex === 3 ? "tabs active-tabs" : "tabs"}
                                    onClick={() => toggleSelectedTab(3)}>
                                    Assignments
                                </div> 
                            </div>
                            <div className="tab-content-vaid ">
                                <div className={broadIndex === 1 ? "content-tab-inner content-active" : "content-tab-inner"}>
                                    <BroadSheetMain broadSheet={broadSheet} emptyList={emptyList} />                           
                                </div>

                                <div className={broadIndex === 2 ? "content-tab-inner content-active" : "content-tab-inner"}>
                                    <BSheetCensusReport broadIndex={broadIndex} person={person} />                           
                                </div>
                                <div className={broadIndex === 3 ? "content-tab-inner content-active" : "content-tab-inner"}>
                                    <BSheetAssigning person={person} />                           
                                </div>
                            </div>              
                        </div>
                    )
                }
                 
              </div>
            </div>
        </div>
        {
            processing && (
                <div className="img-processer">
                    <img src={CONSTANTS.SECOND_PROCESSING} className="proc-item" />
                </div>
            )
        } 
    </div>    
  )
}

export default TBVCatBroadSheet