import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { writeFile, utils } from 'xlsx';

function Divergents() {
    const [processing, setProcessing]                                          = useState(false);

    const [pictureList, setPictureList]                                        = useState([]);
    const [pictureListArchive, setPictureListArchive]                          = useState([]);
    const [imageFeedback, setImageFeedback]                                    = useState("");
    const [showSumbitImage, setShowSumbitImage]                                = useState(false);
    const [selectedPicture, setSelectedPicture]                                = useState(null);
    const [globalFilter, setGlobalFilter]                                      = useState('');
    const [calalogueItems, setCalalogueItems]                                  = useState();
    const [diverUpdate, setDivertUpdate]                                       = useState();
    const { user }                                                             = useContext(AuthContext);
    
    const mainImageListRef                                                     = useRef();
    const catalogueIDRef                                                       = useRef();

    useEffect(() => {
       getAllCatalogue();
    },[])
    useEffect(() => {
        getAllTheDivergents();

     },[diverUpdate])

    const checkMainImage  = () => {
        setImageFeedback("");
        if(mainImageListRef.current.files.length > 0){
          //  console.log("Preview");
           // setImagePreview(URL.createObjectURL(mainImageListRef.current.files[0]));
           setShowSumbitImage(true);
           //console.log(mainImageListRef.current.files);
        }
    }
    function handleTableFilter(event) {

        const newData = pictureListArchive.filter(row => {
            const searchText = event.toLowerCase();

        return ( 
            row.barcode.toLowerCase().includes(searchText) ||
            row.title.toLowerCase().includes(searchText) ||
            row.price.toLowerCase().includes(searchText) ||
            row.stockcode.toLowerCase().includes(searchText) )
        });
        setPictureList(newData);
    }
    //////////___
    const headerTableDie = (
        <div className="table-header flex justify-content-between">
            <h4 className="mx-0 my-1"></h4>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => handleTableFilter(e.target.value)} placeholder="Search Script..." />
            </span>
        </div>
    );
    //////////___

    const rowNumberTemplate = (rowData, column) => {
        return (
            <span>{column.rowIndex + 1}</span>
        );
    };
     //////////___
    const getAllTheDivergents = async () => {
        // console.log(user);
     
        try{
            const res = await axios.get(CONSTANTS.API_URL +"pictures/divergent/list" );
                //console.log(res);
                //console.log("Divergents");
                //console.log(res.data);
                setPictureList(res.data);
                setPictureListArchive(res.data);
    
            }catch(erros){
            console.log(erros);     
            }
    }

    const getAllCatalogue = async () => {
        setProcessing(true);
        try {
          
          const res = await axios.get(CONSTANTS.API_URL +"catalogues/list", {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
    
          //console.log(res.data);
          setCalalogueItems(res.data);
          setProcessing(false);
        } catch (err) {
          setProcessing(false);
          console.log(err);          
        }
      }
    
    const createSubmissionForImages = async () => {
        try{

            setProcessing(true);   
            /////////////////_______>>>>
           
           // const totalImgTotal = mainImageListRef.current.files.length;
            const selectedId = catalogueIDRef.current.value;

          

            for (const picInput of  mainImageListRef.current.files) {     
                //console.log("____>>>>");
                //console.log(picInput);
                // console.log(picInput.name);
                var barcode      = picInput.name.substr(0, picInput.name.lastIndexOf('.'));
                // console.log(barcode);
              
                //////////////////=====>
                if (isNumericOnly(barcode)) {
                    const formData = new FormData();
                    formData.append("catalogueid", selectedId);
                        formData.append("divimurl", picInput);
                        formData.append("barcode", barcode);
                        
                        //setDivertUpdate()
                        const responseDivergent = await axios.post(CONSTANTS.API_URL+"pictures/transfer/divergent" ,formData);
                        console.log(responseDivergent);
                        console.log(responseDivergent.status);

                        if(responseDivergent.status){
                            setDivertUpdate("Successfully added " + barcode)
                        }
                    
                  }
                /////////////////====>
            }

            //console.log("totalImgTotal");
           // console.log(totalImgTotal);
            //formData.append("imgurl", mainImageListRef.current.files[0]);
            /////////////////_______>>>>
            setProcessing(false);   
        }catch(err){
            setProcessing(false);   
            console.log(err);
        }
    }
    function isNumericOnly(str) {
        return /^\d+$/.test(str);
    }

    const createExcelExport = () => {
        try{

            if(pictureList.length > 0){
                console.log("Touch lets go");
                //console.log(pictureList.length);
                //console.log(pictureList);

                // Create a new workbook
                const wb = utils.book_new();

                // Convert data to a worksheet
                const ws = utils.json_to_sheet(pictureList);

                // Append the worksheet to the workbook
                utils.book_append_sheet(wb, ws, "Picture List");
                const d = new Date();
                const stringName = "Picture_" + d.getTime() + "_Excel.xlsx";
                // Generate and download the Excel file
                writeFile(wb, stringName);
                console.log(stringName);
            }
        }catch(err){
            console.log(err)
        }
    }

  return (
    <div className="main-standard">
        <h2 className="lbl-title-page">Divergents</h2>
        <div className="card">
            <div className="card-body">
                <div className="stream-images">
                    <div className="flexme">
                        <div className="">
                            <p>Please Add up to 10 images at a time.</p>
                            <input type="file" className="form-control w80" onChange={() => checkMainImage()} ref={mainImageListRef} multiple  accept="image/*" required/>
                        </div>
                        <div className="">
                            <div className="form-group">

                                <select className="form-control" ref={catalogueIDRef}>
                                    {
                                        calalogueItems && (
                                            <>
                                                <option value="_">Select Catalogue for auto add.</option>
                                                {

                                                    calalogueItems.map((catItem, index) => {
                                                        return (
                                                            <option value={catItem._id} key={index}>{catItem.title}</option>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }
                              </select>
                              
                          </div>
                           <div className="">
                           {
                                showSumbitImage && (
                                    <Button
                                            label="Submit pictures"
                                            className="btn btn-picky btsm1 mr-2"
                                            onClick={() => createSubmissionForImages()}
                                    />
                                )
                            }
                           </div>
                        </div>
                        <div className="">
                            {
                                diverUpdate && (<div className="msg-processing-item mglt10"><span>{diverUpdate}</span></div>)
                            }
                            {
                                imageFeedback &&
                            <div className="alert alert-warning">{imageFeedback}</div>
                            }
                        </div>
                    </div>
                </div>
                <div className="page-items">
                    {
                        pictureList && (
                            <DataTable 
                                value={pictureList} 
                                header={headerTableDie}
                                paginator rows={20} rowsPerPageOptions={[20, 30, 40]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={(globalFilter) ? globalFilter : ''}
                                responsiveLayout="scroll">
                                    <Column field="rowIndex" header="#" body={rowNumberTemplate}></Column>
                                    <Column field="barcode" header="Barcode"></Column>
                                    <Column field="title" header="Title"></Column>
                                    <Column field="price" header="Price"></Column>
                                    <Column field="stockcode" header="Stockcode"></Column>
                            </DataTable>
                        )
                    }
                 </div>
                 <div className="pd10">
                     <Button
                           label="Export Excel List"
                           className="btn btn-picky btsm1 mr-2"
                           onClick={() => createExcelExport()}
                       />
                 </div>
            </div>

            {
                processing && (
                    <div className="img-source">
                    <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default Divergents