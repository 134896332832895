import React, { useContext, useEffect, useRef, useState } from 'react'
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { AuthContext } from '../context/AuthContext';
import { RadioButton } from 'primereact/radiobutton';

function AddPhotos() {

    const { user }                                = useContext(AuthContext);

    const [processing, setProcessing]             = useState(false);
    const [imagepreview, setImagePreview]         = useState(CONSTANTS.PREVIEW_IMAGE);
    const [supplierList, setSupplierList]         = useState([]);
    const [presentList, setPresentList]           = useState([]);
    const [selectedCat, setSelectedCat]           = useState(null);
    const [selectedCatOb, setSelectedCatOb]       = useState({});
    const [catMess, setCAtMess]                   = useState("");
    const [taxonomyList, setTaxonomies]           = useState([]);
    const [search, setSearch]                     = useState("");

    const mainImage = useRef();
    
    const barcodeRef                   = useRef();
    const titleRef                     = useRef();
    const onlinedescRef                = useRef();
    const decriptionRef                = useRef();
    const presentationRef              = useRef();
    const sizeRef                      = useRef();
    const unitCheckRef                 = useRef();
    const costPriceRef                 = useRef();
    const priceRef                     = useRef();
    const supplierRef                  = useRef();
    const messageScrollRef             = useRef();

    const [addMsg, setAddMsg]          = useState("");
    const [msgClass, setMsgClass]      = useState("");
    
    const [myTag, setMyTag]            = useState([]);
    
    const checkMainImage  = () => {
        if(mainImage.current.files[0]){
          //  console.log("Preview");
            setImagePreview(URL.createObjectURL(mainImage.current.files[0]));
        }
    }
    
    async function handleFormUpload(e) {
        e.preventDefault();   
        setProcessing(true);   
        try{
           
                ///////////////////******** FORM WORK */
                //console.log("Continue to process");
                const formData = new FormData();
         
                let supName  = supplierRef.current.options[supplierRef.current.selectedIndex].text;
             
                const tagsNotInTxList = myTag.filter(tagy => !taxonomyList.some(user => user.tagName === tagy));
                let tagsFleet = myTag.toString();
                let tagUp     = tagsNotInTxList.toString();

                formData.append("imgurl", mainImage.current.files[0]);
                formData.append("barcode", barcodeRef.current.value);
                formData.append("title", titleRef.current.value);
                formData.append("onlineDesc", onlinedescRef.current.value);
                formData.append("description", decriptionRef.current.value);
                formData.append("brand", supName);  
                formData.append("size", sizeRef.current.value);
                formData.append("cost_price", costPriceRef.current.value);
                formData.append("price", priceRef.current.value);
                formData.append("presentation", presentationRef.current.value);   
                formData.append("unit", unitCheckRef.current.value);   
                formData.append("supplierid", supplierRef.current.value); 
                formData.append("draftmode", false);   
                formData.append("tagslist", tagsFleet); 
                formData.append("tagupdate", tagUp); 
             
                const response = await axios.post(CONSTANTS.API_URL+"pictures/upload" ,formData, {
                     Headers: {
                         'Content-Type': 'multipart/form-data',
                         token: "Bearer "+ user.accessToken
                     }
                  });
                 
                 if(response.data.status === "success"){
                        setAddMsg("Image added successfully.");
                        setMsgClass("alert-success");

                        e.target.reset();
                        messageScrollRef.current.scrollIntoView();
                        setImagePreview(CONSTANTS.PREVIEW_IMAGE);
                   }else {
                       console.log("Server blocked it");
                       setAddMsg(response.data.message);
                       setMsgClass("alert-danger");
                   }
                 
                ///////////////////******** FORM WORK */
         

            setProcessing(false);
        } catch (err){
          console.log("Something went wrong ");
          console.log(err);
          setProcessing(false);
          console.log("Proccess:" + processing);
        }       
    }

    useEffect(() => {
        getListOfSuppliers();
        getListOfPresentations();
        getListOfTaxonomies();
    },[]);

    function handleKeyDown(e) {
       
        if(e.key !== 'Enter') return
        const value = e.target.value
        if(!value.trim()) return
    
          setMyTag([...myTag, value.toLowerCase()])
          e.target.value = ''
          e.preventDefault();
      }

    const getListOfSuppliers = async () => {
        try{
            const response = await axios.get(CONSTANTS.API_URL+"suppliers/list", {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });

            //console.log(response.data);
            setSupplierList(response.data);
            //console.log("*********")
        }catch(errs){
            console.log("Photo get List of Suppliers");
            console.log(errs);
            if(errs.message === "Request failed with status code 403"){
                localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
                document.location.href="/login";
               }
        }
    }

    const getListOfPresentations = async () => {
        try{
            const response = await axios.get(CONSTANTS.API_URL+"pictures/presentations", {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });

            //console.log(response.data);
            setPresentList(response.data);
            //console.log("*********")
        }catch(errs){
        
            console.log(errs);
        }
    } 

    const getListOfTaxonomies = async () => {
        try{
            const response = await axios.get(CONSTANTS.API_URL+"tags/list", {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
            //console.log(response.data);
            setTaxonomies(response.data);
            //console.log("######*********")
        }catch(errs){
            console.log(errs);
        }
    }

    const addThisTag = (tagItem) => {        
   
        if (myTag.indexOf(tagItem.tagName) > -1) {         
        } else {
            setMyTag([...myTag, tagItem.tagName.toLowerCase()])
        }       
    }

    const removethis = (place, index) => {
        console.log(place);
        console.log(index);
         
        setMyTag([
            ...myTag.slice(0, index),
            ...myTag.slice(index + 1)
          ]);
    }
    return (
        <div className="main-standard">
           <h2 className="lbl-title-page">Add Photos</h2>
            <div className="page-items mobOff">
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={handleFormUpload}>
                            <div className="flexme">
                                <div className="flx-50">                      
                                    <div className="left-data-on">
                                        <div className="form-group">
                                            <div className="lbldesc">Barcode*: </div>
                                            <input type="number" ref={barcodeRef} className="form-control w80" required/>
                                        </div>
                                        <div className="form-group">
                                            <div className="lbldesc">Title*: </div>
                                            <input type="text" ref={titleRef} className="form-control w80" required/>
                                        </div>
                                        <div className="form-group">
                                            <div className="lbldesc"> Online Description: </div>
                                            <textarea className="form-control w80 htbig" ref={onlinedescRef}></textarea>
                                        </div>
                                        <div className="form-group">
                                            <div className="lbldesc">Full Description: </div>
                                            <textarea className="form-control w80 htbigger" ref={decriptionRef}></textarea>
                                        </div>
                                       
                                        <div className="form-group">
                                            <div className="lbldesc">Presentation: </div>                                         
                                          
                                                {presentList && (
                                                    <select className="form-control w80" ref={presentationRef}>
                                                        {presentList.map((prese, i) => {
                                                            return (<option key={i} className="opt" value={prese.title}>{prese.title}</option>)
                                                        })}
                                                    </select>
                                                )}
                                                  
                                        </div>
                                        <div className="form-group">
                                            <div className="lbldesc">Add Tags: </div>
                                            <div className="tags-input-container">
            
                                                {
                                                    myTag.map((tag, index) => (
                                                    <div className="tag-item" key={index}>
                                                        <span className="text">{tag}</span>
                                                        <span className="close" onClick={() => removethis(tag, index)}>x</span>
                                                    </div>
                                                    ))
                                                }
                                            <input onKeyDown={handleKeyDown} type="text" className="edit-in" placeholder="Add Tag..." />
                                        
                                            </div>
                                        </div>
                                        <div className="form-group">
                                           <div className="row rws-form-g">
                                                <div className="col-md-6">
                                                        <div className="f-group">
                                                            <div className="lbldesc">Size: </div>
                                                            <input type="text" ref={sizeRef} className="form-control wfull" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="f-group">
                                                            <div className="lbldesc">Unit Check: </div>
                                                            <select className="form-control wfull" ref={unitCheckRef}>
                                                                <option className="opt" value=""></option>
                                                                <option className="opt" value="g">g</option>
                                                                <option className="opt" value="mg">mg</option>
                                                                <option className="opt" value="ml">ml</option>
                                                                <option className="opt" value="mcg">mcg</option>
                                                                <option className="opt" value="L">L</option>
                                                                <option className="opt" value="µg">µg</option>                                                   
                                                            </select>
                                                        </div>
                                                    </div>
                                           </div>
                                        </div>
                                       
                                        <div className="form-group">
                                           <div className="row rws-form-g">
                                                <div className="col-md-6">
                                                        <div className="f-group">
                                                            <div className="lbldesc">Cost Price: </div>
                                                            <input type="text" ref={costPriceRef} className="form-control wfull" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="f-group">
                                                            <div className="lbldesc">Price: </div>
                                                            <input type="text" ref={priceRef} className="form-control wfull" />
                                                        </div>
                                                    </div>
                                           </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="lbldesc">Select Supplier: </div>
                                            {
                                                supplierList && (
                                                    <select className="form-control w80" ref={supplierRef}>
                                                         {supplierList.map((sup) => {
                                                            return <option className="opt" value={sup._id} key={sup._id}>{sup.title}</option>
                                                         }) }
                                                    </select>
                                                )
                                            }
                                        </div>

                                        <div className="form-group">
                                            <div className="lbldesc">Main Image*: </div>
                                            <input type="file" className="form-control w80" onChange={() => checkMainImage()} ref={mainImage}  accept="image/*" required/>
                                        </div>
                                        <div className="form-group">
                                           <img className="previewItem" src={imagepreview} alt="PickNick Preview image" />
                                        </div>
                                    
                                        
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-picky" disabled={processing}>Create</button>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="flx-50">
                                    <div className="right-data-on">
                                        <div className="form-group">
                                            {catMess && (
                                                <div className="alert alert-warning">{catMess}</div>
                                            )
                                            }
                                            <div className="lbldesc">Tags*: </div>
                                            <div className="tag-box ctbox1">    
                                                                                
                                                {
                                                    taxonomyList && (                                                         
                                                        <div className="cat-boxer add-photo-rad">
                                                            <div className="search-tag addsearch">
                                                                <input type="text" className="form-control" onChange={(e) => setSearch(e.target.value)} placeholder="Search Tag" />
                                                            </div>  
                                                            {
                                                                taxonomyList
                                                                  .filter((item) => {
                                                                    return search.toLowerCase() === ''
                                                                    ? item
                                                                    : item.tagName.toLowerCase().includes(search)
                                                                  })
                                                                  .map((catitem, indi) => {
                                                                    return (
                                                                        <div className="mp-tg-item" key={indi} onClick={() => addThisTag(catitem)}>
                                                                            <div className="" >                                                                                   
                                                                                    <label htmlFor={"tago" +indi}>{catitem.tagName}</label>  
                                                                            </div>
                                                                         
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>

                                        </div>
                                        
                                    </div>
                                    {
                                        processing && (
                                            <div className="img-source">
                                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                                            </div>
                                        )
                                    }
                                     
                                </div>
                                
                            </div>
                        </form>
                        <div className="mgtop30 sp-group">
                                            <p>Please save the title of the image as a barcode <span className="italisizer">190000001.jpg or 190000001.png</span></p>
                                            <p>Please  ensure that a square image is uploaded. Recommended dimmensions are <strong>1000 x 1000</strong></p>
                                            <img src={CONSTANTS.IMAGE_PREV_SIZE} className="sizeex" />
                                            <p>Please  ensure that the image size is less than  <strong>1.5</strong> mb</p>                                                
                                    </div>
                        {
                                    
                            addMsg && (
                                <div className={"alert-area " + msgClass}>
                                    {addMsg}
                                </div>
                            )
                        }
                        <div ref={messageScrollRef}></div>
                    </div>
                </div>
                <div className="mgtee-spacer">
                   <hr/>
                </div>
               
            </div>
        </div>
      )
}

export default AddPhotos