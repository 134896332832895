import React from 'react'
import { Link } from 'react-router-dom';

function ListTable({datapics, displayTp}) {
    
  return (
    <div className={"inner-display " + displayTp}>
              {datapics.map((prod, i) => {
                  return (
                    <div className="pr-b-outer" key={i}>
                        <Link to={"/photo/" + prod._id} className="lk-dash">
                            <div className="block-item" >                         
                                <div className="pc-lbl pc-barcode">
                                    {prod.barcode}
                                </div>
                                <div className={`sc-item  ${prod?.schedule ? "has-scedu" : "no-scedu"}`}>
                                  { 
                                    prod?.schedule ? 
                                       <>
                                        S{prod.schedule}
                                       </>
                                     : 
                                        ""
                                  }   
                                </div>
                                                   
                                <div className="pc-lbl pc-image" >           
                                  <img src={prod.imgurl} className="disp-image" loading="lazy" />        
                                </div>
                                <div className="pc-lbl pc-title">
                                     {prod.title}                                
                                </div>   
                                {(displayTp === "list-layout") && (
                                  <div className="pc-l pc-descri">{prod.onlineDesc}</div>
                                )}                  
                            </div> 
                      </Link>
                  </div>
                  )
              })
              }
     </div>
  )
}

export default ListTable