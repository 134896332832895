import React, { useContext, useRef, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { AuthContext } from '../context/AuthContext';

function TaxonomyAdd() {
    const { user } = useContext(AuthContext);

    const [processing, setProcessing]       = useState(false);
    const [addMsg, setAddMsg]               = useState("");
    const [msgClass, setMsgClass]           = useState("");

    const tagTitleRef                 = useRef();
    const tagTextAreaRef              = useRef();

    async function handleFormTaggs(e) {
        e.preventDefault();  
        
        if(tagTitleRef.current.value.length > 3  ){
            setAddMsg("");
            setProcessing(true);

            try{
               

                const taglogItem = {
                    "tagName":tagTitleRef.current.value.toLowerCase(),
                    "tagDescription": tagTextAreaRef.current.value,
                    "img_url": "",
                }

                console.log(taglogItem);
                
                console.log(CONSTANTS.API_URL+"tags/");
                console.log(user.accessToken);
                const response = await axios.post(CONSTANTS.API_URL+"tags/", taglogItem, {
                    headers: {
                        token: "Bearer "+ user.accessToken
                    }
                });

                console.log(response.data);

                tagTitleRef.current.value     = "";
                tagTextAreaRef.current.value  = "";
                setAddMsg("Tag has been created");
                setMsgClass("alert-success");
                setProcessing(false);

              }catch(err){
                setAddMsg("Something went wrong, please try again later.");
                setMsgClass("alert-danger")
                console.log(err.status);  
                setProcessing(false); 
                if(err.message === "Request failed with status code 403"){
                    localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
                    document.location.href="/login";
                   }             
              }

        }else {
            setAddMsg("Please add the title of the catalogue, no less than 3 characters");
            setMsgClass("alert-danger")
        }
    }

  return (
    <div className="main-standard">
       <h2 className="lbl-title-page">Add Tags</h2>
       <div className="list-catalogue">
           <div className="card">
              <div className="card-body">
                 <form  onSubmit={handleFormTaggs}>
                     <div className="form-group">
                        <div className="lbldesc">Tag Title*: </div>
                        <input type="text" ref={tagTitleRef} className="form-control wfull" required/>
                     </div>
                     <div className="form-group">
                        <div className="lbldesc">Infomation: </div>
                        <textarea ref={tagTextAreaRef} className="form-control wfull h100f"></textarea>                        
                     </div>
                     
                     <div className="form-group">
                         <button type="submit" className="btn btn-picky" disabled={processing}>Create Tag</button>
                     </div>
                     {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                            </div>
                        )
                     }
                     {
                        addMsg && (
                            <div className={"alert-area " + msgClass}>
                               {addMsg}
                            </div>
                        )
                      }
                 </form>
              </div>
           </div>

       </div> 
    </div>
  
  )
}

export default TaxonomyAdd