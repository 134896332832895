import React, { useState } from 'react'
import * as CONSTANTS from "../../../CONSTANTS";
import axios from "axios";

function BSheetAssigning({person}) {

  const [processing, setProcessing]                                                       = useState(false);
  const [included, setIncluded]                                                           = useState("");
  const [exclude, setExcluded]                                                            = useState("");

  const handleExecuteIncludedProducts = async () => {
    try{

      setProcessing(true);

      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: CONSTANTS.API_URL + 'broadsheets/include/docs/products',
        headers: { 
          'token': 'Bearer ' + person.accessToken
        },
      };
      
      const res = await axios.request(config); 
      console.log("Included");
      console.log(res);
      setIncluded(res.data.length);
      setProcessing(false);
    }catch(err){
      console.log(err);
      setProcessing(false);
    }
  }

  const handleExecuteExcludedProducts = async () => {
    try{

      setProcessing(true);

      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: CONSTANTS.API_URL + 'broadsheets/excluded/docs/products',
        headers: { 
          'token': 'Bearer ' + person.accessToken
        },
      };
      
      const res = await axios.request(config); 
      console.log("Exclude");
      console.log(res);
      setExcluded(res.data.length);
      setProcessing(false);
    }catch(err){
      console.log(err);
      setProcessing(false);
    }
  }

  return (
    <div className="inner-tab-content">
        <table className="table table-striped">
          <tbody>
            <tr>
              <td>Find out how many products are in the pictures picnick database.</td>
              <td>Find out how many products are not in the pictures picknick database</td>
            </tr>
            <tr>
              <td><button className="btn btn-picky" onClick={() => handleExecuteIncludedProducts()}>Included</button></td>
              <td><button className="btn btn-picky" onClick={() => handleExecuteExcludedProducts()}>Excluded</button></td>
            </tr>
            <tr>
              <td>
                {
                  included && (<h3>{included}</h3>)
                }
              </td>
              <td>
                {
                  exclude && (<h3>{exclude}</h3>)
                }
              </td>
            </tr>
          </tbody>
        </table>
     
        {
            processing && (
                <div className="img-processer">
                    <img src={CONSTANTS.SECOND_PROCESSING} className="proc-item" />
                </div>
            )
        }
    </div>
  )
}

export default BSheetAssigning