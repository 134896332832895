import React, { useEffect, useState } from 'react'
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { Link, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { useNavigate } from "react-router-dom";
import DlgCatUsers from '../DlgCatUsers';
import DlgImportTAssign from '../DlgImportTAssign';
import DlgAddTokenCat from '../DlgAddTokenCat';
import DlgClassificationCat from '../DlgClassificationCat';
import DlgCatalogueProductEdit from '../DlgCatalogueProductEdit';
import DlgCatalogueProductMigrate from '../DlgCatalogueProductMigrate';


function TBVcatListing({person, catalogue, setCatalogue}) {

  const [processing, setProcessing]                              = useState(false);
  const [pictures, setPictures]                                  = useState([]);
  const [selectedLinkedBarcodeIds, setSelectedLinkedBarcodeIds]  = useState([]);
  const [members, setMembers]                                    = useState([]);
  const [memeberIds, setMemberIds]                               = useState([]);
  const [allPictures, setAllPictures]                            = useState([]);
  

  const [displayLinkDialog, setDisplayLinkDialog]                = useState(false);
  const [dsplyImprtAsignDialog, setDsplyImprtAsignDialog]        = useState(false);
  const [dspCreateCatTokenDialog, setDspCreateCatTokenDialog]    = useState(false);
  //dialogue for setting up the categories
  const [showClassificationCats, setShowClassificationCats]      = useState(false);
  //dialogue for editing product price and assigning
  const [showCatsProductEdit, setShowCatsProductEdit]            = useState(false);
  const [showProductsMigrate, setShowProductMigrate]             = useState(false);

  const [currentProduct, setCurrentProduct]                      = useState();

  const [btnDeleteCat, setBtnDeleteCat]                          = useState(true);
  const [btnConfirmUnlink, setConfirmUnlink]                     = useState(true);

  const [globalFilter, setGlobalFilter]                          = useState('');
  const [message, setMessage]                                    = useState("");
  const [messaClass, setMessClass]                               = useState("");

  const [selectedPictures, setSelectedPictures]                  = useState(null);

  const [showDialogueUsers, setShowDialogueUsers]                = useState(false);

  const params                                                   = useParams();

  const [updateMe, setUpdateMe]                                  = useState(0);
  const [userSetup, setUserSetup]                                = useState(0);
  const [totalPics, setTotalPics]                                = useState(0);

  const [calalogueItems, setCalalogueItems]                      = useState();

  const navigate                                                 = useNavigate();

  useEffect(() => {
    getCatalogue();
    getAllPictures();
  }, [updateMe]);

  useEffect(() => {
      if(memeberIds){    
         performUserFectch();
      }
      
  },[showDialogueUsers, userSetup]);

  const performUserFectch = async () => {
    setProcessing(true);    
    const stringy = JSON.stringify(memeberIds);
  
      try{
         
          const res = await axios.get(CONSTANTS.API_URL + "catalogues/getusers?userssearch=" + stringy, {
            headers: {
              token: "Bearer " + person.accessToken
            }
          });
          
         // console.log(res.data.Users);
          setMembers(res.data.Users);
          setUpdateMe(updateMe + 1);
          setProcessing(false);
      } catch(err){
          console.log(err);
          setProcessing(false);
      }      
  }

  const getCatalogue = async () => {
    setProcessing(true);
      try { 

        const res = await axios.get(CONSTANTS.API_URL + "catalogues/details/" + params.id, {
          headers: {
            token: "Bearer " + person.accessToken
          }
        });

        setCatalogue(res.data[0]);
        setPictures(res.data[0].pics);
        setTotalPics(res.data[0].pics.length);
        setMemberIds(res.data[0].userIds);
        setProcessing(false);
      } catch (err) {
        console.log(err);
        setProcessing(false);
        if(err.message === "Request failed with status code 403"){
          localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
          document.location.href="/login";
        } 
      }

  }

  const getAllPictures = async ()=> {
      try{

        const res = await axios.get(CONSTANTS.API_URL + "pictures/assembly" , {
            headers: {
              token: "Bearer " + person.accessToken
            }
          });

        setAllPictures(res.data);
      }catch(err){
        console.log(err);
      }
  }

  const searchAllPictures = async (e) => {
    setProcessing(true);
      try {
        
        const res = await axios.get(CONSTANTS.API_URL + "pictures/search?searchfield=" + e.target.value, {
          headers: {
            token: "Bearer " + person.accessToken
          }
        });

        setAllPictures(res.data);
        setProcessing(false);
      } catch (err) {
        console.log(err);
        setProcessing(false);
      }
  }

  const linkPictures = async () => {
      // return ids from selectedLinkedBarcodeIds,
      const barcodeIds = selectedLinkedBarcodeIds.map((item) => {
        return item.barcode;
      });

      // append barcodeIds to catalogue.barcodeIds
      const newBarcodeIds = catalogue.barcodeIds.concat(barcodeIds);

      setProcessing(true);
      // update catalogue with new barcodeIds

      try {
        const res = await axios.put(CONSTANTS.API_URL + "catalogues/" + params.id, {
          barcodeIds: newBarcodeIds,
        }, {
          headers: {
            token: "Bearer " + person.accessToken
          }
        });
        //console.log("Updated catalogue");
        //console.log(res);
        setProcessing(false);
        setDisplayLinkDialog(false);
        setSelectedLinkedBarcodeIds([]);
        setAllPictures([]);
        setUpdateMe(updateMe + 1);
        
      } catch (err) {
        console.log(err);
        setProcessing(false);
      }


  }

  const unLinkPictures = async () => {
    // return ids from selectedLinkedBarcodeIds,
    const barcodeIds = pictures.map((item) => {
      return item.barcode;
    });

    // append barcodeIds to catalogue.barcodeIds
    const newBarcodeIds = catalogue.barcodeIds.filter((item) => {
      return !barcodeIds.includes(item);
    });

    setProcessing(true);

    const paradelete = {
      "paradelete":  params.id
    }
    try {
      //const res = await axios.post(CONSTANTS.API_URL + "catalogues/deletebarcodes", paradelete, {
      const res = await axios.post(CONSTANTS.API_URL + "catalogues/delete/barcodes/picncat", paradelete, {
          headers: {
            token: "Bearer " + person.accessToken
          }
        });
      
      //console.log("Updated catalogue");
      //console.log(res);
      setProcessing(false);
      setDisplayLinkDialog(false);
      setSelectedLinkedBarcodeIds([]);
      setAllPictures([]);
      setUpdateMe(updateMe + 1);
    } catch (err) {
      console.log(err);
      setProcessing(false);
    }
  }

  const deleteCatalogue = async () => {
    setProcessing(true);
    // update catalogue with new barcodeIds

    try {
      const res = await axios.delete(CONSTANTS.API_URL + "catalogues/terminate/" + params.id ,{
        headers: {
          token: "Bearer " + person.accessToken
        }
      });
      //console.log(res.data);
      //console.log("Re deletele");
      setProcessing(false);
      navigate("/catalogue", { replace: true });

    }catch(err){
      setProcessing(false);
      console.log("Terminate Err");
      console.log(err);
    }    
  }
  ///////////////////////
  const changeDeleteCatStatus = async () => {
    setBtnDeleteCat(false);
  }

  const changeUnLinkPicturesCatStatus = async () => {
    setConfirmUnlink(false);    
  }
  
  const viewCatalogueUsers = () => {
    setShowDialogueUsers(true);
  }

  const headerMain = (
    <div className="table-header flex flexymobile justify-content-between">
      <h4 className="mx-0 my-1">Catalogue Pictures {(totalPics > 0) && (<span className="tot-title">{totalPics}</span>)}</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" onClick={(e) => setGlobalFilter(e.target.value)}/>
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Pictures..." />
      </span>
    </div>
  );

  const linkHeader = (
    <div className="table-header flex flexymobile justify-content-between">
      <h4 className="mx-0 my-1">Search for pictures to link</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={searchAllPictures} placeholder="Search Pictures..." />
      </span>
    </div>
  );


  const imageBodyTemplate = (rowData) => {
    //console.log(rowData);
    return <img src={rowData.imgurl} alt="image" className="product-image" width={80} />;
  }

  const viewCategorizationTemplate = (rowData) => {
    var categoryString = "";
    if(rowData.categoryclass != null && rowData.categoryclass != undefined && rowData.categoryclass.length > 0){

      const catOb  = rowData.categoryclass.filter(category => category.catalogueid === params.id);
      if(catOb.length > 0){
        categoryString = catOb[0].catTitle;
      }
    }
    return categoryString;
  }
  
  const viewCataloguePhotoTemplate = (rowData) => {
    //setCurrentProduct(rowData);
    return <Button className="btn btn-picky btsm1"  label="Edit Product"  onClick={() => updateCurrenctProduct(rowData)}/>
  }

  const viePricingTemplate = (rowData) => {
    var pricing = "";

    if(rowData.storePrice != null && rowData.storePrice != undefined && rowData.storePrice.length > 0){
      const pricingData = rowData.storePrice.filter(priceData => priceData.catalogueid === params.id);
      if(pricingData.length > 0){
        pricing = pricingData[0].price;
      }     
    } 
    return pricing;
  }

  const linkStatusTemplate = (rowData) => {

    // if id is in catalogue.barcodeIds, return true
    //console.log("$$$$$$$$$$$$$$$$$$$$$$$$")
    // console.log(rowData);
    if (catalogue.barcodeIds.includes(rowData.barcode)) {
      return <Tag value="Linked" severity="success" />;
    }else{
      return <Tag value="Not Linked" severity="danger" />;
    }
  }

  const onlineTitle = (rowData) => {
    //console.log(rowData);
    return <div className="clip-description">{rowData.onlineDesc}</div>;
  }

  const removeUserToListUsers = async (member) =>{
    setProcessing(true); 
    const memberId = member._id;
    const allMemberIds = memeberIds;
  
    const indexForDel = allMemberIds.indexOf(memberId);
    allMemberIds.splice(indexForDel, 1);
   
    try {
   
      var config = {
        method: 'delete',
        url: CONSTANTS.API_URL + 'catalogues/removeuser?srcdcat='+params.id,
        headers: { 
          'token': 'Bearer ' + person.accessToken,
          'Content-Type': 'application/json'
        },
        data : {
          "userIds": allMemberIds
        }
      };
      const res = await axios(config);

      setUserSetup(userSetup - 1);
      setProcessing(false);
     

    }catch(err){
      setProcessing(false);
      console.log("Delete user from Catalogue Array Err");
      console.log(err);
    }

  }

  const addUserToListedUsers = async (member) => {
      setProcessing(true);

      const memberId = member._id;

      try{
      
       const allMemberIds = memeberIds;
       allMemberIds.push(memberId);

        var config = {
          method: 'post',
          url: CONSTANTS.API_URL + 'catalogues/addcheck?srcfcat='+params.id,
          headers: { 
            'token': 'Bearer ' + person.accessToken,
            'Content-Type': 'application/json'
          },
          data : {
            "userIds": allMemberIds
          }
        };
        const respo = await axios(config);
        /////pppppppppppp
        //const respo = await axios.post(CONSTANTS.API_URL + "catalogues/addcheck?srcfcat=" + catalId);
        //console.log("coooos");
        console.log(respo.data);
        setUserSetup(userSetup + 1);
        setProcessing(false);
    }catch(erUser){
        console.log("Dialogue Error user");
        console.log(erUser);
        setProcessing(false);
        
    }
  }
  
  const processSalesLayerTage = async () => {
    setProcessing(true);
    try{
      setMessClass("");
      setMessage("");

      const resCat = await axios.get(CONSTANTS.SALES_LAYER_PIC_CATY + "&protag=" + catalogue.salescode);
      
       if(resCat.status === 200){
        setMessClass("alert alert-warning");
        setMessage("Still Processing...");
        setProcessing(true);
        const newBar = {
          "catid" : catalogue._id,
          "barcodes" : resCat.data.barcodes
        }
       //console.log(newBar)
        const repCatalogueUpdate = await axios.post(CONSTANTS.API_URL + "catalogues/barcodeupdate", newBar, {
          headers: {
            'token': 'Bearer '+ person.accessToken,
            'Content-Type': 'application/json'
          },
        });
      
        setUpdateMe(updateMe + 1);
        setMessClass("");
        setMessage("");
        setProcessing(false);
       }else {
        console.log("Terrible service");
        setMessClass("alert alert-danger");
        setMessage("We are experiencing server issues, Please try again later.");
       }
       setProcessing(false);
    } catch(err){
      console.log(err);
      setProcessing(false);
    }
  }

  const updateCurrenctProduct = (rowData) => {
    setCurrentProduct(rowData);
    setShowCatsProductEdit(true)
  }

    
    useEffect(() => {
        getAllCatalogue();
    },[])
  
    const getAllCatalogue = async () => {
      setProcessing(true);
      try {
        
        const res = await axios.get(CONSTANTS.API_URL +"catalogues/list", {
              headers: {
                  token: "Bearer "+ person.accessToken
              }
          });
  
        //console.log(res.data);
        setCalalogueItems(res.data);
        setProcessing(false);
      } catch (err) {
        setProcessing(false);
        console.log(err);
        if(err.message === "Request failed with status code 403"){
          localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
          document.location.href="/login";
         }
        console.log("ERRRRRRR")
      }
    }
  
  return (
    <div className="bx-catalo">
        <div className="list-catalogue">
        <div className="card">

              <DlgImportTAssign 
                  dsplyImprtAsignDialog={dsplyImprtAsignDialog} 
                  setDsplyImprtAsignDialog={setDsplyImprtAsignDialog} 
                  setAllPictures={setAllPictures}
                  updateMe={updateMe}
                  setUpdateMe={setUpdateMe}
                  catid={params.id}  
                  person={person} 
                />

              <Dialog visible={displayLinkDialog} onHide={() => setDisplayLinkDialog(false)} style={{ width: '50vw' }}
                footer={
                    <div>
                      {/* link button */}
                      <Button label="Link" icon="pi pi-link" onClick={linkPictures} disabled={selectedLinkedBarcodeIds.length === 0} />
                      <Button className="p-button-danger" label="Close" onClick={() => setDisplayLinkDialog(false)} />
                    </div>
                  }>
                  {
                  allPictures && (
                      <DataTable value={allPictures} paginator rows={20} rowsPerPageOptions={[20, 40, 60]}
                          header={linkHeader}
                          globalFilter={globalFilter}
                          emptyMessage="No pictures found"
                          className="p-datatable-sm"
                          selection={selectedLinkedBarcodeIds}
                          onSelectionChange={
                            (e) => {
                              //console.log(e.value)
                              setSelectedLinkedBarcodeIds(e.value)
                            }}
                        >
                      <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}  
                      />
                      <Column header="Image" body={imageBodyTemplate}></Column>
                      <Column field="barcode" header="Barcode"></Column>
                      <Column field="title" header="Title"></Column>
                      <Column header="Description" body={onlineTitle}></Column>
                      <Column header="Link Status" body={linkStatusTemplate}></Column>
                    </DataTable>
                  )}
                
              </Dialog>

              <DlgCatUsers 
                  showDialogueUsers={showDialogueUsers} 
                  setShowDialogueUsers={setShowDialogueUsers} 
                  addUserToListedUsers={addUserToListedUsers} 
                  removeUserToListUsers={removeUserToListUsers} 
                  members={members} 
                  memeberIds={memeberIds} />

              <DlgAddTokenCat 
                  dspCreateCatTokenDialog={dspCreateCatTokenDialog} 
                  setDspCreateCatTokenDialog={setDspCreateCatTokenDialog} 
                  catid={params.id} 
                  person={person} 
                  
                />
              
              <DlgClassificationCat
                showClassificationCats={showClassificationCats}
                setShowClassificationCats={setShowClassificationCats}
                catid={params.id} 
                catalogue={catalogue}
                person={person} 
                />

                <DlgCatalogueProductEdit
                  showCatsProductEdit={showCatsProductEdit}
                  setShowCatsProductEdit={setShowCatsProductEdit}
                  catid={params.id} 
                  catalogue={catalogue}
                  product={currentProduct}
                  person={person} 
                />

              {
                  catalogue && (
                    <DlgCatalogueProductMigrate
                        showProductsMigrate={showProductsMigrate} 
                        setShowProductMigrate={setShowProductMigrate}
                        currentCatalogue={catalogue}
                        personAdmin={person} 
                        />
                  )
              }
                

              <div className="mssagepads">
                      {
                        message && (
                          <div className={messaClass}>
                            {message}
                          </div>
                        )                          
                      }
              </div>
              <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">               
                <div className="but-small pd10 mt-3 lg:mt-0">
                  <Button
                    label="Import pictures"
                    className="btn btn-picky btsm1 mr-2"
                    onClick={() => setDsplyImprtAsignDialog(true)}
                  />

                  <Button
                      className="btn btn-picky btsm1 mr-2"
                      label="Link Pictures"
                      onClick={() => setDisplayLinkDialog(true)}
                    />
                  
                  <Button
                      className="btn btn-picky btsm1 mr-2"
                      label="View Catalogue Users"
                      onClick={viewCatalogueUsers}
                    />
                
                    <Button
                        label="Token"
                        className="btn btn-picky btsm1 mr-2"
                        onClick={() => setDspCreateCatTokenDialog(true)}
                      />
                    
                    <Button 
                        label="Categories"
                        className="btn btn-picky btsm1 mr-2"
                        onClick={() => setShowClassificationCats(true)}
                      />

                    <Button 
                        label="Migrate"
                        className="btn btn-picky btsm1 mr-2"
                        onClick={() => setShowProductMigrate(true)}
                      />           

                  {
                    catalogue.salestag && 
                        <Button
                          label="Update Catlogue"
                          className="btn btn-picky btsm1 mr-2"
                          onClick={() => processSalesLayerTage()}
                        />
                  }



                </div>
              </div>
              <div>
                <DataTable
                  value={pictures}
                  header={headerMain}
                  paginator rows={10} rowsPerPageOptions={[10, 20, 40]}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                  responsiveLayout="scroll"
                  globalFilter={(globalFilter) ? globalFilter : ''}
                  selection={selectedPictures}
                  onSelectionChange={e => setSelectedPictures(e.value)}
                >
                  <Column selectionMode="multiple" headerStyle={{ width: '3em' }} ></Column>
                  <Column header="Image" body={imageBodyTemplate}></Column>
                  <Column field="barcode" header="Barcode"></Column>
                  <Column field="title" header="Title"></Column>
                  <Column header="Pricing" body={viePricingTemplate}></Column>  
                  <Column header="Categories" body={viewCategorizationTemplate}></Column>  
                  <Column header="View" body={viewCataloguePhotoTemplate}></Column>    
                </DataTable>
                
              </div>
              {
                processing && (
                    <div className="img-source">
                    <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc profixed" />
                    </div>
                )
              }
              </div>
              <div className="retreat-box bottom-foot">
              {
                person.isAdmin && (
                  <div className="addel-foot">
                      <Button
                              className="btn btn-picky mr-2"
                              label="Confirm Catalogue Delete"
                              onClick={changeDeleteCatStatus}                              
                            />

                      <Button
                              className="btn btn-picky mr-2"
                              label="Delete Catalogue"
                              onClick={deleteCatalogue}
                              disabled={btnDeleteCat}
                            />
                    </div>
                )
              }  
                
                <div className="addel-foot">
                      <Button
                              className="btn btn-picky mr-2"
                              label="Confirm Unlink Pictures"
                              onClick={changeUnLinkPicturesCatStatus}                              
                            />

                      <Button
                          className="btn btn-picky btsm1 mr-2"
                          label="Un-Link Pictures"
                          onClick={unLinkPictures}
                          disabled={btnConfirmUnlink}
                        />

                    </div>
              </div> 
        </div>
    </div>
  )
}

export default TBVcatListing