import React, { useEffect, useState } from 'react'
import BarChart from '../../charts/BarChart'
import * as CONSTANTS from "../../../CONSTANTS";
import axios from "axios";

function BSheetCensusReport({broadIndex, person}) {

    const [processing, setProcessing]                                           = useState(false);
    const [viewChart1, setViewChart1]                                           = useState(false);
    const [theChartData, setTheChartData]                                       = useState(null);

    const [noChartMessage, setNoChartMessage]                                   = useState("");

    useEffect(() => {
      if(viewChart1 === true){
        fetchChartData();
      }
    }, [viewChart1]);


    const fetchChartData = async () => {
        setProcessing(true);
        try{
          
              let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL + 'broadsheets/histograph/count/v2',
                headers: { 
                  'token': 'Bearer ' + person.accessToken
                },
              };
              
              const res = await axios.request(config); 
              /*
              console.log("||||||||||||||||");
              console.log(res);
              console.log("||||||||||||||||");
              */
              if(res.data.length > 0){
                setTheChartData({
                    labels: res.data.map((data) => data.countNumber),
                    datasets:[{
                        label: "Used Products",
                        data: res.data.map((data) => data.totalCount),
                        backgroundColor: ["#ce2030"]
                    }] 
                  });
              }else {
                setNoChartMessage();
              }             
              setProcessing(false);

        }catch(err){
            console.log(err);
            setProcessing(false);
        }
    }
  return (
    <div className="inner-tab-content">
       <div className="pd10">
          <p>
          A census of all the products that are consumed by letstrade that from the Ackermans Database and how many times each product is called.
          </p>
         <button onClick={() => setViewChart1(true)} className="btn btn-picky">View Chart</button>
         <div className="chat-box">
            {
                theChartData && (<BarChart chartData={theChartData} />)
            }
            
         </div>
       </div>
       {
            noChartMessage.length > 0  && (
                <div className="alert alert-warning" >{noChartMessage}</div>
            )
       }
       {
            processing && (
                <div className="img-processer">
                    <img src={CONSTANTS.SECOND_PROCESSING} className="proc-item" />
                </div>
            )
        } 
    </div>
  )
}

export default BSheetCensusReport