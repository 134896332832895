import React, { useState } from 'react'

function BroadSheetMain({broadSheet, emptyList}) {
    
    const [search, setSearch]                                                             = useState('');
    
  return (
    <div className="inner-tab-content">
        <div className="form-block-search">
            <input type="text" className="form-control" onChange={(e) => setSearch(e.target.value)} placeholder="Search for Product" />
        </div>
        <div className="broad-list">
                {
                        broadSheet &&
                        broadSheet.length > 0 && (
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr className="strip-header">
                                        <th>#</th>
                                        <th>Title</th>
                                        <th>Barcode</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Count</th>
                                        <th>Update</th>
                                        <th>Digit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    broadSheet
                                        .filter((picture) => {
                                            return search.toLowerCase() === ''
                                            ? picture
                                            : picture.title.toLowerCase().includes(search.toLocaleLowerCase()) || picture.barcode.toLowerCase().includes(search.toLocaleLowerCase())
                                        })
                                        .map((picture, index) => {
                                            return <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{picture.title}</td>
                                                    <td>{picture.barcode}</td>
                                                    <td>{picture.qty}</td>
                                                    <td>{picture.price}</td>
                                                    <td>{picture.reform.count}</td>
                                                    <td>{new Date(picture.reform.update).toLocaleDateString(undefined, {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric'
                                                        })}
                                                    </td>
                                                    <td>{picture.digit}</td>
                                                </tr>
                                        }
                                   )
                                }
                                </tbody>
                            </table>
                        )
                    }
 
                    {
                        emptyList && (
                            <div className="alert alert-warning">{emptyList}</div>
                        )
                    }
        </div>
    </div>
  )
}

export default BroadSheetMain