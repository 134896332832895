import React from 'react';
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import Login from './pages/Login';
import Sidebar from "./components/Side/Sidebar";
import Tobnavbar from "./components/Top/Tobnavbar";
import { Outlet } from 'react-router-dom';

function AppProtectedRoutes() {
    const { user } = useContext(AuthContext);

    var isValid = user ? true : false;

  return (
    isValid ? 
         <div className="dashingboard-outer">   
                <div className="dashingboard">
                    <div className="side-dash">
                            <Sidebar member={user} />
                    </div>
                     <div className="main-dash-content">
                        <div className="top-nav">
                            <Tobnavbar />
                        </div>
                        <div className="main-content-arena">
                            <Outlet />
                        </div>
                    </div>
                </div>
         </div>
        :
        <div className="cont-un-auth">
            <Login />
        </div>
  )
}

export default AppProtectedRoutes