import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { useNavigate } from "react-router-dom";

import TBVcatSummary from '../components/cataloging/TBVcatSummary';
import TBVcatListing from '../components/cataloging/TBVcatListing';
import TBVCatNewPictureListing from '../components/cataloging/TBVCatNewPictureListing';
import TBVCatBroadSheet from '../components/cataloging/TBVCatBroadSheet';


function CatalogueDetails() {

  const { user }                                                  = useContext(AuthContext);
  const [catalogue, setCatalogue]                                 = useState(null);
  const [activeIndex, setActiveIndex]                             = useState(1);

  const [processing, setProcessing]                                                           = useState(false);
  const params                                                                                = useParams();

  useEffect(() => {
    getCatalogue();
  },[])

  //functions
  const toggleSelectedTab = (index) => {
      setActiveIndex(index);
  }

  const getCatalogue = async () => {
    setProcessing(true);
      try { 

        const res = await axios.get(CONSTANTS.API_URL + "catalogues/details/" + params.id, {
          headers: {
            token: "Bearer " + user.accessToken
          }
        });
        //console.log("EFFCTOR the catalogue");
       // console.log(res.data[0]);
        setCatalogue(res.data[0]);
        //console.log(res.data[0].broadsheeting);
        setProcessing(false);
      } catch (err) {
        console.log(err);
        setProcessing(false);
        if(err.message === "Request failed with status code 403"){
          localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
          document.location.href="/login";
        } 
      }

  }

  return (
      <div className="main-standard">
        <h2 className="lbl-title-page">Catalogue Detials</h2>
        <div className="card">
               <div className="desc-content">
                  <div className="font-medium text-3xl text-900 mb-3">{catalogue && catalogue.title}</div>
                  <div className="text-500 mb-5">{catalogue &&  catalogue.information}</div>
                
              </div>
        </div>
        {
          catalogue && (
              <div className="tab-dashboard-item">
                  <div className="side-dash-settings-nav">
                    
                      <div  className={activeIndex === 1 ? "tabs active-tabs" : "tabs"}
                          onClick={() => toggleSelectedTab(1)}>
                          Home
                      </div>
                      <div  className={activeIndex === 2 ? "tabs active-tabs" : "tabs"}
                          onClick={() => toggleSelectedTab(2)}>
                        List
                      </div> 
                      <div  className={activeIndex === 3 ? "tabs active-tabs" : "tabs"}
                          onClick={() => toggleSelectedTab(3)}>
                        New List
                      </div>   
                      {
                        catalogue.broadsheeting === true && (<div  className={activeIndex === 4 ? "tabs active-tabs" : "tabs"}
                                                              onClick={() => toggleSelectedTab(4)}>
                                                            Broadsheet
                                                          </div>  
                                                        )
                      }    
                                      
                    
                  </div>

                  <div className="tab-content-box">                          
                          <div className={activeIndex === 1 ? "content-tab content-active" : "content-tab"}>
                              <TBVcatSummary person={user} />                           
                          </div>
                          <div
                              className={activeIndex === 2 ? "content-tab content-active" : "content-tab"}
                              >
                                <TBVcatListing person={user} catalogue={catalogue} setCatalogue={setCatalogue} />                           
                          </div>
                          <div
                              className={activeIndex === 3 ? "content-tab content-active" : "content-tab"}
                              >
                                <TBVCatNewPictureListing person={user} catalogue={catalogue} setCatalogue={setCatalogue} activeIndex={activeIndex} />                           
                          </div>
                          <div
                              className={activeIndex === 4 ? "content-tab content-active" : "content-tab"}
                              >
                                <TBVCatBroadSheet person={user} activeIndex={activeIndex} />                           
                          </div>
                          
                  </div>
          </div>
          )
        }
        
        {
              processing && (
                  <div className="img-source">
                    <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                  </div>
              )
        }             
    </div>
  );
}

export default CatalogueDetails