import React, { useContext, useEffect, useRef, useState } from 'react';
import {useParams} from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import * as CONSTANTS from "../CONSTANTS";
import { Button } from 'primereact/button';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faThumbsUp, faTrash} from '@fortawesome/free-solid-svg-icons';

import axios from "axios";
import DlgEditPhoto from '../components/DlgEditPhoto';
import DlgEditPhotoSupplier from '../components/DlgEditPhotoSupplier';
import DlgEditPhotoCategory from '../components/DlgEditPhotoCategory';
import {useNavigate} from "react-router-dom";
import DlgTagAdd from '../components/DlgTagAdd';

function Photo() {
  const [photo, setPhoto]                                               = useState([]);
  const [processing, setProcessing]                                     = useState(false);

  const { user }                                                        = useContext(AuthContext);
  const params                                                          = useParams();

  const [imageMainpreview, setImagePreview]                             = useState(CONSTANTS.PREVIEW_IMAGE);
  const [unpackpreview, setUnpackPreview]                               = useState(CONSTANTS.PREVIEW_IMAGE);
  const [leftpreview, setLeftPreview]                                   = useState(CONSTANTS.PREVIEW_IMAGE);
  const [rightpreview, setRightPreview]                                 = useState(CONSTANTS.PREVIEW_IMAGE);

  const [thumbsOne, setThumbsOne]                                       = useState(false);
  const [thumbsTwoU, setThumbsTwoU]                                     = useState(false);
  const [thumbsThrL, setThumbsThrL]                                     = useState(false);
  const [thumbsFouR, setThumbsFouR]                                     = useState(false);

  const [delTwoU, setDelTwoU]                                           = useState(false);
  const [delThrL, setDelThrL]                                           = useState(false);
  const [delFouR, setDelFouR]                                           = useState(false);

  const [tagWork, setTagWork]                                           = useState([]);

  const [msMainAlert, setMsgMainAlert]                                  = useState("");
  const [msgUnpackAlert, setMsgUnpageAlert]                             = useState("");
  const [msgLeftAlert, setMsgLeftAlert]                                 = useState("");
  const [msgRightAlert, setMsgRightAlert]                               = useState("");

  const [msUnpackAleDel, setMsgUnpackAleDel]                            = useState("");
  const [msgLeftAleDel, setMsgLeftAleDel]                               = useState("");
  const [msRightAleDel, setMsgRightAleDel]                              = useState("");

  const [showDialPhoto, setShowDialPhoto]                               = useState(false);
  const [showDialPhotoSup, setShowDialPhotoSup]                         = useState(false);
  const [showDialPhotoCategory, setShowDialPhotoCategroy]               = useState(false);
  const [showDialogueTags, setShowDialogueTags]                         = useState(false);
  const [presentList, setPresentation]                                  = useState([]);

  const [updateMe, setUpdateMe]                                         = useState(0);

  const [suppli, setSupplier]                                           = useState({});

  const [occarancess, setUpOccarance]                                   = useState(0);

  const mainImage             = useRef();
  const unpackedImage         = useRef();
  const rightImage            = useRef();
  const leftImage             = useRef();

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  useEffect(() => {
    getPhoto();
    getListOfPresentations();
  },[updateMe]);


  const getPhoto = async () => {

     try {
       
         const res = await axios.get(CONSTANTS.API_URL +"pictures/photo/"+ params.id, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });

          if(res.data.taxonomy.length > 0){
            setTagWork(res.data.taxonomy);
          }
       
         setPhoto(res.data);
         totalCountTotal(res.data.barcode);

         if(res.data.imgUnpack !== ""){
          setDelTwoU(true);
         }
         if(res.data.imgLeft !== ""){
          setDelThrL(true);
         }
         if(res.data.imgRight !== ""){
          setDelFouR(true);
         }
        
      
         getSuppl(res.data);
       } catch (err) {
         console.log(err);
         console.log("ERRRRRRR PHOTO");
         console.log(err.response.data);
         console.log(err.response.status);
         if(err.response.data === "Token is not valid!"){
           localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
           document.location.href="/login";
         }
         
       }
     
 }

 const totalCountTotal = async (barcode) => {
  try{

      const resOccurance = await axios.get(CONSTANTS.API_URL +"catalogues/baroccurrence?code="+ barcode, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });

          
          if(resOccurance.data.length !== 0){
            setUpOccarance(resOccurance.data[0].occurrence);
          }
          

  }catch (err) {
    console.log(err);  
  }
}

 const getListOfPresentations = async () => {
    try{

        const resPresent = await axios.get(CONSTANTS.API_URL+"pictures/presentations", {
            headers: {
                token: "Bearer "+ user.accessToken
            }
          });
         
          setPresentation(resPresent.data);

    }catch(ers){
        console.log(ers);
        console.log("ERR")
    }
  }

 const getSuppl = async (photoItem) => {

    try{
      

      const resSup = await axios.get(CONSTANTS.API_URL +"suppliers/single/"+ photoItem.supplierid, {
          headers: {
              token: "Bearer "+ user.accessToken
          }         
      });
     console.log(resSup.data);
     /*
      if(resSup.data.currentSupplier.length > 0){
        setSupplier(resSup.data.currentSupplier);
      }else{
        const noSup = {"title" : ""}
        setSupplier(noSup);
      }
      */
      if(Object.entries(resSup.data.currentSupplier)){
       //console.log("A");
        setSupplier(resSup.data.currentSupplier);
      }else {
        //console.log("B");
        const noSup = {"title" : ""}
        setSupplier(noSup);
      }
      
    }catch(ers){
      console.log(ers)
    }
 }

  //////////////////////////////////////////////////
  const checkMainImage  = () => {
    if(mainImage.current.files[0]){    
       setImagePreview(URL.createObjectURL(mainImage.current.files[0]));
    }
  }

  async function handleFormUploadMainImage(e) {
   e.preventDefault(); 
      //replace call
      const formData = new FormData();
      ////////////++___________>
      //if the current picture has a domain cjmpicnick2 then it means we need to delete first
      ////###
      if(photo.imgurl.includes("cjmpicknick2")){
        const folderNM = CONSTANTS.IMG_FOLDER;

          let indexy      = photo.imgurl.indexOf(folderNM);
          let resultKey   = photo.imgurl.substr(indexy);
          let barImage    = resultKey.replace(folderNM + "/", "");

        const obImage = {"obImage" : "imgurl"}
          let dataItem = JSON.stringify({
            "objecturl": {obImage},
            "photoid": photo._id,
            "key": resultKey, 
            "deletfile": barImage
          });
          //console.log(dataItem);

          var config = {
            method: 'post',
            url: CONSTANTS.API_URL+"pictures/removeside",
            headers: { 
              'Content-Type': 'application/json',
              'token': "Bearer " + user.accessToken
            },
            data : dataItem
          };

          //removepicture
          const responseRemove = await axios(config);
      }
      ////////////++___________>
    
      const resindx = photo.imgurl.indexOf("uploads/");
     
      let resKey = photo.imgurl.substring(resindx + 8);
  
      formData.append("mainpicurlupdate", mainImage.current.files[0]);
      formData.append("picid", photo._id);
      formData.append("deleturl", resKey);

      setProcessing(true);
     
      try{
        
        const response = await axios.post(CONSTANTS.API_URL + "pictures/mainurlupdate" , formData, {
            Headers: {
              'Content-Type': 'multipart/form-data',
              token: "Bearer " + user.accessToken
            }
          });

          console.log("+++++   response.data   ++++++");
          console.log(response.data);
          setThumbsOne(true)
       
          setUpdateMe(1);
          
        setProcessing(false);
      }catch(err){
        console.log("papalina")
        console.log(err);
        setProcessing(false);
      }
     
  }


//////////////////////////////////////////////////
  const checkUnpackedImage  = () => {
    if(unpackedImage.current.files[0]){
        setUnpackPreview(URL.createObjectURL(unpackedImage.current.files[0]));
    }
  }

  async function handleFormUploadUnpackedImage(e) {
   e.preventDefault(); 

   //image_list UNPACKED : ORDER 1
   let fileNameText = unpackedImage.current.files[0].name.replace(/(.*)\.[^.]+$/, "$1");

   if(fileNameText.indexOf("U_") === 0){
      
      let unpackFileName = fileNameText.substring(2);
   
      if(unpackFileName === photo.barcode){
   
        setMsgUnpageAlert("");

        const formData = new FormData();
          formData.append("sideimageurl", unpackedImage.current.files[0]);
          formData.append("imgtype", "imgUnpack");
          formData.append("picid", photo._id);
        
          setProcessing(true);
          try{
            const responsePicnic = await axios.post(CONSTANTS.API_URL+"pictures/sideimage" ,formData, {
              Headers: {
                'Content-Type': 'multipart/form-data',
                token: "Bearer " + user.accessToken
              }
            });

            //picnic list
          
              setUpdateMe(2);
              setThumbsTwoU(true);
              setMsgUnpageAlert("");

            setProcessing(false);
          }catch(err){
            console.log(err);
            setProcessing(false);
          }
      }else {
        console.log("Barcode is incorrect. It should match the current barcode.");
        setMsgUnpageAlert("Barcode is incorrect. It should match the current barcode.");
      }
   }else {
    console.log("Please use filename 'U_00000barcode.png'");
    setMsgUnpageAlert("Please use filename format as follows 'U_00000barcode.png'");
   }
   /*
    msMainAlert, setMsgMainAlert]         = useState("");
    const [msgLeftAlert, setMsgLeftAlert]        = useState("");
    const [msgRightAlert, setMsgRightAlert]      = useState("");
    */
  }

  ///////////////////////////////////////////////////
  const checkLeftImage  = () => {
    if(leftImage.current.files[0]){
        setLeftPreview(URL.createObjectURL(leftImage.current.files[0]));
    }
  }
  async function handleFormUploadLeftImage(e) {
   e.preventDefault(); 

    let fileNameText = leftImage.current.files[0].name.replace(/(.*)\.[^.]+$/, "$1");

    if(fileNameText.indexOf("L_") === 0){
        
        let leftFileName = fileNameText.substring(2);
      
        if(leftFileName === photo.barcode){
      
          setMsgUnpageAlert("");
          ///////////////////////////
          const formData = new FormData();
            formData.append("sideimageurl", leftImage.current.files[0]);
            formData.append("imgtype", "imgLeft");
            formData.append("picid", photo._id);
          
            setProcessing(true);
            try{
              const responsePicnic = await axios.post(CONSTANTS.API_URL+"pictures/sideimage" ,formData, {
                Headers: {
                  'Content-Type': 'multipart/form-data',
                  token: "Bearer " + user.accessToken
                }
              });

              //picnic list
            

                setUpdateMe(3);
                setThumbsThrL(true);
                setMsgLeftAlert("");

              setProcessing(false);
            }catch(err){
              //console.log("The ancient of Days");
              //console.log(err);
              setProcessing(false);
            }
          ///////////////////////////
        }else {
          //console.log("Barcode is incorrect. It should match the current barcode.");
          setMsgLeftAlert("Barcode is incorrect. It should match the current barcode.");
        }
    }else {
      //console.log("Please use filename 'L_00000barcode.png'");
      setMsgLeftAlert("Please use filename format as follows 'L_00000barcode.png'");
    }

  }

  ///////////////////////////////////////////////////////
  const checkRightImage  = () => {
    if(rightImage.current.files[0]){
        setRightPreview(URL.createObjectURL(rightImage.current.files[0]));
    }
  }

  async function handleFormUploadRightImage(e) {
   e.preventDefault(); 
   

    let fileNameText = rightImage.current.files[0].name.replace(/(.*)\.[^.]+$/, "$1");

    if(fileNameText.indexOf("R_") === 0){
        
        let rightFileName = fileNameText.substring(2);
       
        if(rightFileName === photo.barcode){
     
          setMsgUnpageAlert("");
          ///////////////////////////
          
            const formData = new FormData();
            formData.append("sideimageurl", rightImage.current.files[0]);
            formData.append("imgtype", "imgRight");
            formData.append("picid", photo._id);
          
            setProcessing(true);
            try{
              const responsePicnic = await axios.post(CONSTANTS.API_URL+"pictures/sideimage" ,formData, {
                Headers: {
                  'Content-Type': 'multipart/form-data',
                    token: "Bearer " + user.accessToken
                }
              });             

                setUpdateMe(4);
                setThumbsFouR(true);
                setMsgRightAlert("")

              setProcessing(false);
            }catch(err){
              
              console.log(err);
              setProcessing(false);
            }
          ///////////////////////////
        }else {
          console.log("Barcode is incorrect. It should match the current barcode.");
          setMsgRightAlert("Barcode is incorrect. It should match the current barcode.");
        }
    }else {
      console.log("Please use filename 'R_00000barcode.png'");
      setMsgRightAlert("Please use filename format as follows 'R_00000barcode.png'");
    }

  }
  
  const deleteSideImage = async (varFound) => {
 
    let obImage = {}
    let keyURL = photo.key;
    let deleteURL = "";
    var re = /(?:\.([^.]+))?$/;

    switch(varFound) {
      case 2:
        // upacked
        obImage = {
          "imgUnpack": ""         
        }
        deleteURL = "U_" +photo.barcode + "."+ re.exec(photo.imgUnpack)[1];
        
        break;
      case 3:
        // left
        obImage = {
          "imgLeft": ""
        }
        deleteURL = "L_" +photo.barcode + "."+ re.exec(photo.imgLeft)[1];
              
        break;
      default:
        // right
        obImage = {
          "imgRight": ""
        }
        deleteURL = "R_" +photo.barcode + "."+ re.exec(photo.imgRight)[1];
          
    }
    
    let dataItem = JSON.stringify({
            "objecturl": {
              obImage
            },
            "photoid": photo._id,
            "key": keyURL, 
            "deletfile": deleteURL
          });
    
        try{
    
          var config = {
            method: 'post',
            url: CONSTANTS.API_URL+"pictures/removeside",
            headers: { 
              'Content-Type': 'application/json',
              'token': "Bearer " + user.accessToken
            },
            data : dataItem
          };
          //removepicture
          const responseRemove = await axios(config);

          console.log(responseRemove.data);
        
        }catch(er){
          console.log(er);
          console.log("Updating side image error")
        }
        
    }
  return (
    <div className="main-standard">
        <h2 className="lbl-title-page">Photo</h2>
        <div className="card mobOff">
            {
              photo && (
                <div className="photo-block">
                    <div className="photo-table">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
               
                        <div className="but-small pd10 mt-3 lg:mt-0">
                          <Button className="btn btn-picky btsm1 mr-2" onClick={goBack} >
                            Back
                          </Button>
                          <Button
                              label="Edit"
                              className="btn btn-picky btsm1 mr-2"
                              onClick={() => setShowDialPhoto(true)}
                            />
                           {
                            user.isAdmin && (
                              <Button
                                label="Edit Supplier"
                                className="btn btn-picky btsm1 mr-2"
                                onClick={() => setShowDialPhotoSup(true)}
                              />
                            )
                           }
                         
                         <Button
                              label="Edit Category"
                              className="btn btn-picky btsm1 mr-2"
                              onClick={() => setShowDialPhotoCategroy(true)}
                            />

                            <Button
                               label="Tags"
                               className="btn btn-picky btsm1 mr-2"
                               onClick={() => setShowDialogueTags(true)}
                               />
                        </div> 
                    </div>
                   
                    <DlgEditPhoto  
                        setShowDialPhoto={setShowDialPhoto}
                        showDialPhoto={showDialPhoto}
                        setSelectedPhotoInfo={setPhoto} 
                        selectedPhotoInfo={photo} 
                        presentList={presentList}
                        supplierName={suppli.title || ""}
                        personAdmin={user}
                        />
                     
                    <DlgEditPhotoSupplier
                        setShowDialPhotoSup={setShowDialPhotoSup}
                        showDialPhotoSup={showDialPhotoSup}
                        setPhotoSupplier={setSupplier} 
                        photoSupplier={suppli} 
                        photoid={photo._id}
                        personAdmin={user}
                        />

                    <DlgEditPhotoCategory 
                        showDialPhotoCategory={showDialPhotoCategory} 
                        setShowDialPhotoCategroy={setShowDialPhotoCategroy}
                        photoid={photo._id}
                        pbarcode={photo.barcode}
                        personAdmin={user}
                        setUpdateMe={setUpdateMe}
                        updateMe={updateMe}
                        />
            
                      <DlgTagAdd
                        showDialogueTags={showDialogueTags}
                        setShowDialogueTags={setShowDialogueTags}
                        currentProduct={photo}
                        personAdmin={user}
                       />

                     <div className="res-bow">
                        <div className="calee-sar">
                          <span>Total occurance: </span>  {occarancess}
                        </div>
                     </div>

                      <div className="partiality-mobile-off">
                          <table className="table table-striped table-border">
                            <tbody>
                              <tr>
                                <td className="left-pho-item">
                                  <strong>Barcode</strong>
                                </td>
                                <td className="width30">:</td>
                                <td>{photo.barcode}</td>
                              </tr>
                              <tr>
                                <td className="left-pho-item">
                                  <strong>Title</strong>
                                </td>
                                <td className="width30">:</td>
                                <td>{photo.title }</td>                          
                              </tr>
                              <tr>
                                <td>
                                  <strong>Online Description</strong>
                                </td>
                                <td>:</td>
                                <td>{photo.onlineDesc}</td>
                                
                              </tr>
                              <tr>
                                <td>
                                  <strong>Description</strong>
                                </td>
                                <td>:</td>
                                <td>{photo.description}</td>                        
                              </tr>
                            
                              <tr>
                                <td>
                                  <strong>Size</strong>
                                </td>
                                <td>:</td>
                                <td>{photo.size} {photo.unit}</td>
                            
                              </tr>
                              <tr>
                                  <td>
                                    <strong>Presentation</strong>
                                  </td>
                                  <td>:</td>
                                  <td>{photo.presentation}</td>
                              </tr>
                              <tr>
                                  <td>
                                    <strong>Cost Price</strong>
                                  </td>
                                  <td>:</td>
                                  <td>{photo.cost_price}</td>
                              </tr>
                              <tr>
                                  <td>
                                    <strong>Price</strong>
                                  </td>
                                  <td>:</td>
                                  <td>{photo.price}</td>
                              </tr>
                              <tr>
                                  <td>
                                    <strong>Schedule</strong>
                                  </td>
                                  <td>:</td>
                                  <td>{photo?.schedule}</td>
                              </tr>
                              <tr>
                                  <td>
                                    <strong>Category</strong>
                                  </td>
                                  <td>:</td>
                                  <td>{(photo.category && photo.category.length) && photo.category[0].name}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Draft Status</strong>
                                </td>
                                <td>:</td>
                                <td>{
                                      photo.draftmode  ? 
                                      <div className="">Hidden</div>
                                      : 
                                      <div className="">Visible</div>
                                    }
                                </td>
                              </tr>
                              {
                                suppli && (
                                  <tr>
                                    <td>Supplier</td>
                                    <td>:</td>
                                    <td>{suppli.title}</td>
                                  </tr>
                                )
                              }
                              {
                                tagWork && (
                                  <tr>
                                    <td>Tags</td>
                                    <td>:</td>
                                    <td className="capital">
                                        {
                                          tagWork.map((tagway, i) => {                                    
                                            return (<span className="tagzy" key={i}>
                                                        {tagway}
                                                        {(i < (tagWork.length - 1)) && (", ")}                                                        
                                              </span>
                                              )
                                          })
                                        }
                                    </td>
                                  </tr>
                                )
                              }    
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="photo-images ">
                      {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                            </div>
                        )
                      }
                      <div className="flexme">
                          <div className="vari-flex">
                            <div className="image-block">
                              <h4>Main Image</h4>
                              <img src={photo.imgurl} className="img-calls" />
                              <img className="smallpreview" src={imageMainpreview} alt="PickNick Preview image" />
                            </div>
                            <div className="rep-image">
                                  <form onSubmit={handleFormUploadMainImage}>
                                  <div className="form-group">
                                      <input type="file" className="form-control w80" onChange={() => checkMainImage()} ref={mainImage}  accept="image/*" required/>
                                  </div>
                                  <div className="form-group">
                                        <button type="submit" className="btn btn-picky" disabled={processing}>UPLOAD</button>
                                        
                                        {
                                          thumbsOne && (
                                            <div className="out-sp-success"><div className="add-thumb"><FontAwesomeIcon icon={faThumbsUp} /></div></div>
                                          )
                                        }
                                        
                                  </div>
                                </form>
                            </div>
                          </div>

                          <div className="vari-flex">
                            <div className="image-block">
                               {
                                  delTwoU && (
                                    <button className="btn-nostyle special-btn fl-left" onClick={() => deleteSideImage(2)}>
                                      <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                  )
                                }
                              <h4>Unpacked Image</h4>
                              {
                                (photo.imgUnpack !== "") && (
                                  <img src={photo.imgUnpack} className="img-calls" />
                                )
                              }
                              <img className="smallpreview" src={unpackpreview} alt="PickNick Preview image" />
                            </div>
                            <div className="rep-image">
                                
                                  <form onSubmit={handleFormUploadUnpackedImage}>
                                    <div className="form-group">
                                        <input type="file" className="form-control w80" onChange={() => checkUnpackedImage()} ref={unpackedImage}  accept="image/*" required/>
                                    </div>
                                    <div className="form-group">
                                          <button type="submit" className="btn btn-picky" disabled={processing}>UPLOAD</button>
                                        
                                          {
                                            thumbsTwoU && (
                                              <div className="out-sp-success">
                                                <div className="add-thumb">
                                                  <FontAwesomeIcon icon={faThumbsUp} />
                                                </div>
                                              </div>
                                            )
                                          }
                                    </div>
                                </form>
                                

                                {
                                  msgUnpackAlert && (
                                    <div className="mgtop20 alert alert-danger">
                                         {msgUnpackAlert}
                                    </div>
                                  )
                                }

                                {
                                        msUnpackAleDel && (
                                          <div className="mgtop20 alert alert-warning">
                                            {msUnpackAleDel}
                                          </div>
                                        )
                                      }
                            </div>
                          </div>
                      </div>
                      <div className="flexme">
                          <div className="vari-flex">
                                <div className="image-block">
                                {
                                      delThrL && (
                                        <button className="btn-nostyle special-btn fl-left" onClick={() => deleteSideImage(3)}>
                                          <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                      )
                                    }
                                  <h4>Left Image</h4>
                                  {
                                    (photo.imgLeft !== "") && (
                                      <img src={photo.imgLeft} className="img-calls" />
                                    )
                                  }
                                  <img className="smallpreview" src={leftpreview} alt="PickNick Preview image" />
                                </div>
                                <div className="rep-image">
                                   
                                      <form onSubmit={handleFormUploadLeftImage}>
                                      <div className="form-group">
                                          <input type="file" className="form-control w80" onChange={() => checkLeftImage()} ref={leftImage}  accept="image/*" required/>
                                      </div>
                                      <div className="form-group">
                                            <button type="submit" className="btn btn-picky" disabled={processing}>UPLOAD</button>
                                           
                                            {
                                                thumbsThrL && (
                                                  <div className="out-sp-success"><div className="add-thumb"><FontAwesomeIcon icon={faThumbsUp} /></div></div>
                                                )
                                              }
                                      </div>
                                    </form>
                                    
                                    {
                                      msgLeftAlert && (
                                        <div className="mgtop20 alert alert-danger">
                                             {msgLeftAlert}
                                        </div>
                                      )
                                    }

                                    {
                                        msgLeftAleDel && (
                                          <div className="mgtop20 alert alert-warning">
                                            {msgLeftAleDel}
                                          </div>
                                        )
                                      }
                                </div>
                              </div>
                              <div className="vari-flex">
                                  <div className="image-block">
                                      {
                                          delFouR && (
                                            <button className="btn-nostyle special-btn fl-left" onClick={() => deleteSideImage(4)}><FontAwesomeIcon icon={faTrash} /></button>
                                          )
                                        }
                                    <h4>Right Image</h4>
                                    
                                    {
                                      (photo.imgRight !== "") && (
                                        <img src={photo.imgRight} className="img-calls" />
                                      )
                                    }
                                    <img className="smallpreview" src={rightpreview} alt="PickNick Preview image" />
                                  </div>
                                  <div className="rep-image">
                                        
                                        <form onSubmit={handleFormUploadRightImage}>
                                        <div className="form-group">
                                            <input type="file" className="form-control w80" onChange={() => checkRightImage()} ref={rightImage}  accept="image/*" required/>
                                        </div>
                                        <div className="form-group">
                                              <button type="submit" className="btn btn-picky" disabled={processing}>UPLOAD</button>
                                              
                                              {
                                                thumbsFouR && (
                                                  <div className="out-sp-success"><div className="add-thumb"><FontAwesomeIcon icon={faThumbsUp} /></div></div>
                                                )
                                              }
                                              
                                        </div>
                                      </form>
                                      
                                      {
                                        msgRightAlert && (
                                          <div className="mgtop20 alert alert-danger">
                                              {msgRightAlert}
                                          </div>
                                        )
                                      }

                                      {
                                        msRightAleDel && (
                                          <div className="mgtop20 alert alert-warning">
                                            {msRightAleDel}
                                          </div>
                                        )
                                      }
                                  </div>
                                </div>

                          </div>
                    </div>
                </div>
              )
            }
        </div>
    </div>
  )
}

export default Photo