import React, { useEffect, useState } from 'react'
import DlgLinkPicturesCat from '../DlgLinkPicturesCat';
import { Button } from 'primereact/button';
import axios from "axios";
import * as CONSTANTS from "../../CONSTANTS";


//Verion 2
function TBVCatNewPictureListing({person, catalogue, setCatalogue , activeIndex}) {

    const [processing, setProcessing]                                                           = useState(false);
    const [showLinkPicturesDialogue, setShowLinkPicturesDialogue]                               = useState(false);

    const [picturesList, setPicturesList]                                                       = useState([]);
    const [search, setSearch]                                                                   = useState('');

    const [listUdate, setListUdate]                                                             = useState(0);
   
    const [page, setPage]                                                                       = useState(1);
    const [limit, setLimit]                                                                     = useState(40);
    const [nextNavString, setNextNavString]                                                     = useState("");
    const [emptyList, setEmptyList]                                                             = useState("");
   

  useEffect(() => {

    if(activeIndex === 3){
        getAllCurrentPictures();        
    }
  }, [activeIndex, listUdate]);

  const getAllCurrentPictures = async () => {
    setProcessing(true);
    setEmptyList("");
    try{

        const collectData = {
            "catalogueid" : catalogue._id,
            "page" : 1,
            "limit" : limit
        }
  
       const res = await axios.put(CONSTANTS.API_URL + "pictures/accumulate/catalogue/v2/pics", collectData, {
                headers: {
                token: "Bearer " + person.accessToken
                }
            });
            
        
        if(res.data.pictures.length > 0){
            setPicturesList(res.data.pictures);
            setNextNavString(res.data.next.navi);
            setPage(prev => prev + 1);
        }else {
            setEmptyList("No pictures in this catalogue");
            setNextNavString("");
        }
      
        setProcessing(false);
    }catch(err){
        console.log(err);
        setProcessing(false);
    }
  }

  /*
  const handleNextAppend = async () => {
    try{
        const collectData = {
            "catalogueid" : catalogue._id,
            "page" : page,
            "limit" : limit
        }
        console.log(collectData);

        const res = await axios.put(CONSTANTS.API_URL + "pictures/accumulate/catalogue/v2/pics", collectData, {
            headers: {
            token: "Bearer " + person.accessToken
            }
        });
       
        if(res.data.pictures.length > 0){

            setPicturesList((prevPictureList) => [...prevPictureList, ...res.data.pictures]);
            setNextNavString(res.data.next.navi);
            setPage(prev => prev + 1);
        }
    }catch(err){
        console.log(err);
    }
  }
  */
  return (
    <div className="bx-catalo">
        <div className="list-catalogue">
            <div className="card">
            <div class="font-medium text-3xl text-900 mb-3">Catalogue Products</div>
                <div className="sect-cont-dial ">
           
                  <DlgLinkPicturesCat 
                        showLinkPicturesDialogue={showLinkPicturesDialogue} 
                        setShowLinkPicturesDialogue={setShowLinkPicturesDialogue} 
                        catid={catalogue._id}  
                        person={person} 
                        setListUdate={setListUdate}
                    />
                    <div className="button-row">
                        <Button
                            label="Link Pictures"
                            className="btn btn-picky btsm1 mr-2"
                            onClick={() => setShowLinkPicturesDialogue(true)}
                        />
                    </div>
                </div>
                <div className="sect-cont-dial form-block-search">
                    <input type="text" className="form-control" onChange={(e) => setSearch(e.target.value)} placeholder="Search for Product" />
                </div>
                <div className="sect-cont-dial">
                    {
                        picturesList &&
                        picturesList.length > 0 && (
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Title</th>
                                        <th>Barcode</th>
                                        <th>Schedule</th>
                                        <th>Unit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    picturesList
                                        .filter((picture) => {
                                            return search.toLowerCase() === ''
                                            ? picture
                                            : picture.title.toLowerCase().includes(search.toLocaleLowerCase()) || picture.barcode.toLowerCase().includes(search.toLocaleLowerCase())
                                        })
                                        .map((picture, index) => {
                                            return <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{picture.title}</td>
                                                    <td>{picture.barcode}</td>
                                                    <td>{picture.schedule}</td>
                                                    <td>{picture.unit}</td>
                                                </tr>
                                        }
                                   )
                                }
                                </tbody>
                            </table>
                        )
                    }

                    {
                        /*
                        search.length === 0 &&(
                            <>
                            {

                                nextNavString && 
                                nextNavString === "on" && (
                                    <div className="navigation-strip">
                                         <button className="btn btn-picky btsm1 mr-2" onClick={() => handleNextAppend()}>Next</button>  
                                    </div>
                                )
                                }
                            </>
                        )
                        */
                    }
                    {
                        emptyList && (
                            <div className="alert alert-warning">{emptyList}</div>
                        )
                    }
                </div>
            </div>

            {
                processing && (
                    <div className="img-source">
                      <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                    </div>
                )
            }
        </div>    
    </div>
  )
}

export default TBVCatNewPictureListing