import React, { useEffect, useRef, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import * as XLSX from 'xlsx';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUpload, faFileExcel, faList } from '@fortawesome/free-solid-svg-icons';

function TBVPicUploadSchedule({person, activeNumber}) {
    const [processing, setProcessing]                                   = useState(false);

    const [messageText, setMessageText]                                 = useState("");
    const [messageClass, setMessageClass]                               = useState("");

    const [listFeedBack, setListFeedBack]                               = useState([]);

    const mainScheduleListRef                                           = useRef();
    const textImageStandRef                                             = useRef();

    useEffect(() => {

    },[])


    const handleUploadScheduleList = async (e) => {
        e.preventDefault();

        try{

            const excelDocument = mainScheduleListRef.current.files[0];
            const data          = await excelDocument.arrayBuffer();
            
            var workbook = XLSX.read(data);  
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const dataJson  = XLSX.utils.sheet_to_json(worksheet);
           // console.log(dataJson);
            const pictureCount = dataJson.length;

            if (pictureCount > 0){
                setProcessing(true);
                for (let i = 0; i < pictureCount; i++) {
                    console.log("{{{{{{{{{{{{{{{{}}}}}}}}}}}}");   
                    console.log("{{{{{{{{{{{{{{{{}}}}}}}}}}}}");   
                    console.log("{{{{{{{{{{{{{{{{}}}}}}}}}}}}");   
                    console.log("{{{{{{{{{{{{{{{{}}}}}}}}}}}}");   

                    const barcodesUpdate = {
                        "barcode" :  dataJson[i].barcode,
                        "stringUrl":textImageStandRef.current.value
                     }

                     console.log(barcodesUpdate);       
                    
                    try{
                        const responseUpdate = await axios.put(CONSTANTS.API_URL + "pictures/set/schedule/product" , barcodesUpdate, {
                            headers: {
                              token: "Bearer " + person.accessToken
                            }
                          });
                          console.log(responseUpdate);   
                          var messageBack   = "";

                          if(responseUpdate.status === 200){
                            messageBack = "Successfully added - " + responseUpdate.data.title + " (" + responseUpdate.data.barcode + ")";
                          }else {
                            messageBack = dataJson[i].barcode + " - Update unsuccesfull.";
                          }
                         

                          setListFeedBack([...listFeedBack, messageBack]);
                          console.log("*****");
                          console.log(listFeedBack);

                    }catch(erItem){
                        console.log(erItem)
                    }
                    
                }

                setProcessing(false);
                setMessageClass("alert-success");
                setMessageText("Processing is complete.");
              
            }else {
                setMessageClass("alert-danger");
                setMessageText("Excell sheet is empty");
            }

        }catch(err){
            console.log(err);
            setProcessing(false);
        }
        
    }
  return (
    <div className="tv-box">
        <h3>Upload Schedule</h3>
        <div className="base">
            <div className="rs row">
                <div className="col-md-8">
                        <form onSubmit={handleUploadScheduleList}>
                            <div className="input-block-group">                                       
                                <input type="file" ref={mainScheduleListRef} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" className="form-control" required/>
                            </div>     
                            <div className="input-block-group">                                       
                                <input type="text" ref={textImageStandRef} placeholder="Image URL" className="form-control" required/>
                            </div>                                      

                            <div className="button-row">
                                <button className="btn btn-picky" >  
                                    <FontAwesomeIcon icon={faUpload} /> Import
                                </button>  
                            </div>  
                        
                        </form>
                </div>
                <div className="col-md-4">
                    <div className="rep-feed">
                    {

                            listFeedBack && (
                                <ul>
                                    {
                                    listFeedBack.map((item) => (
                                        <li key={item}>{item}</li>
                                    )
                                    )
                                    }
                                </ul>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="cot mgtop10">
            {
                messageText && (
                    <div className={"alert " + messageClass}>
                        {messageText}
                    </div>
                )
            }
        </div>
        {
            processing && (
                <div className="img-source">
                    <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                </div>
            )
        }
    </div>
  )
}

export default TBVPicUploadSchedule